// import React, { createRef, useCallback } from "react";
import React from "react";
import axios from "axios";
import { Redirect} from "react-router-dom";

export default function petition(key, val) {
  const json = require("../../config.json");
  const urlBase = json.urlBase;
  const token = JSON.parse(localStorage.getItem("persist:demo4-auth"));
  // const urlBase =  "https://api.itpscorp.com/crmui";
  // const urlBase =  "http://api.itpscorp.com/dev/crmui";
  if (token.authToken) {
    var petition_get = urlBase + "/global-search?" + key + "=" + val;
    var config = {
      headers: {
        Authorization: "Bearer " + token.authToken.replace(/['"]+/g, ""),
      },
    };

    return axios.get(petition_get, config);

  } else {  
    // return <Redirect exact from="/" to="/logout" />;
  }
}
