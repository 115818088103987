import React from "react";
import Users from "./users/users";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../../partials/content/Portlet";
import Graphics from "./graphics/graphics";

const billingPayments = {
  minimum_payment_amount: 1,
  maximum_payment_amount: 2,
  average_month: 3,
  average_payment_amount: 3,
};

const List = [
  [1, 2, 3],
  [1, 2, 3],
  [1, 2, 3],
  [1, 2, 3],
  [1, 2, 3],
  [1, 2, 3],
  [1, 2, 3],
]

export default class DashboardTickets extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        {/* <Users /> */}
        <div className="">
          <Users history={this.props.history} List={List} />
        </div>
        {/* <div className="col-xl-6">
          <Portlet fluidHeight={true}>
            <PortletBody>
              <Graphics
                months={["uno", "dos", "tres", "cuatro", "cinco"]}
                monthsValues={[1, 2, 3, 4, 5]}
                max={3}
                billingPayments={billingPayments}
              />
            </PortletBody>
          </Portlet>
        </div> */}
      </>
    );
  }
}
