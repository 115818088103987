import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { put, takeLatest } from "redux-saga/effects";
import { getUserByToken } from "../../crud/auth.crud";
import * as routerHelpers from "../../router/RouterHelpers";

export const actionTypes = {
  Login: "[Login] Action",
  Logout: "[Logout] Action",
  Register: "[Register] Action",
  UserRequested: "[Request User] Action",
  UserLoaded: "[Load User] Auth API",
};

let values = JSON.parse(window.localStorage.getItem("persist:demo4-auth"));
console.log(values);
let path = window.location.pathname;
console.log(window.location.pathname);
const initialAuthState = {
  user:
    window.location.pathname === "/auth/login" ||
    window.location.pathname === "/" ||
    window.location.pathname === "/logout" ||
    window.location.pathname === "/auth/logout" ||
    window.location.pathname === "/auth/reset-password" 
      ? undefined 
      : values === null? undefined: values.user,
  authToken:
    window.location.pathname === "/auth/login" ||
    window.location.pathname === "/" ||
    window.location.pathname === "/logout"||
    window.location.pathname === "/auth/reset-password" 

      ? undefined
      : values === null ?undefined:values.authToken,
};
  
// alert(initialAuthState.user);
// const initialAuthState = {
//   user: undefined,
//   authToken: undefined
// };

export const reducer = persistReducer(
  { storage, key: "demo4-auth", whitelist: ["user", "authToken"] },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.Login: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Register: {
        const { authToken } = action.payload;

        return { authToken, user: undefined };
      }

      case actionTypes.Logout: {
        routerHelpers.forgotLastLocation();
        console.log(initialAuthState);
        // storage.removeItem("persist:demo4-auth");
        return {
          user: undefined,
          authToken: undefined,
        };
      }

      case actionTypes.UserLoaded: {
        const { user } = action.payload;

        return { ...state, user };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  login: (authToken) => ({ type: actionTypes.Login, payload: { authToken } }),
  register: (authToken) => ({
    type: actionTypes.Register,
    payload: { authToken },
  }),
  logout: () => ({ type: actionTypes.Logout }),
  requestUser: (user) => ({
    type: actionTypes.UserRequested,
    payload: { user },
  }),
  fulfillUser: (user) => ({ type: actionTypes.UserLoaded, payload: { user } }),
};

export function* saga() {
  yield takeLatest(actionTypes.Login, function* loginSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.Register, function* registerSaga() {
    yield put(actions.requestUser());
  });

  yield takeLatest(actionTypes.UserRequested, function* userRequested() {
    const { data: user } = yield getUserByToken();
    //¿ yield put(actions.fulfillUser(user));
  });
}
