import React from "react";
import Notice from "../../../../../partials/content/Notice";
import { Table, ButtonToolbar, Button, Dropdown, Badge } from "react-bootstrap";
import Paper from "@material-ui/core/Paper";
import ReactHtmlParser, {
  processNodes,
  convertNodeToElement,
  htmlparser2,
} from "react-html-parser";
import DescriptionModal from "./DescriptionModal/DescriptionModal";
import Edit from "./Edit/Edit";
import { Link } from "react-router-dom";
import petition_user from "../../../petitions/petition_user"
import DateUtcToLocal from "../../../../../widgetsITP/DateUtcToLocal/DateUtcToLocal"

export default class ViewTickets extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      allData: [],
      html: "",
      DescriptionModal: false,
      Edit: false,
      element: {},
      states: ["all open", "new", "pending_customer", "pending_itp", "closed"],
      subject_contains: null,
      start_date: null,
      end_date: null,
      status: null,
    };
  }
  DescriptionModalClose = () => {
    this.setState({ DescriptionModal: false });
  };
  EditClose = () => {
    this.setState({ Edit: false });
  };

  componentDidMount() {
    let data = [];
    let serviceAddon = [];
    petition_user("mytickets", "")
      .then(({ data: { result } }) => {
        console.log(result);
        this.setState({
          data: result,
          allData: result,
          status: null,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  DescriptionModal() {
    if (this.state.DescriptionModal) {
      return (
        <DescriptionModal
          show={this.state.DescriptionModal}
          onHide={this.DescriptionModalClose}
          html={this.state.html}
        />
      );
    }
  }
  Edit() {
    if (this.state.Edit) {
      return (
        <Edit
          show={this.state.Edit}
          onHide={this.EditClose}
          html={this.state.html}
          element={this.state.element}
        />
      );
    }
  }
  filter(url) {
    petition_user("mytickets", url)
      .then(({ data: { result } }) => {
        this.setState({
          data: result,
          allData: result,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  viewRelation(val) {
    let parent = [];
    let enabled = false;
    this.state.serviceAddon.forEach((element) => {
      if (element.parent_product_id === val) {
        parent.push(element);
        enabled = true;
      }
    });
    this.setState({ parent: parent });
  }
  render() {
    return (
      <>
        {this.DescriptionModal()}
        {this.Edit()}
        {/* <ButtonToolbar style={{ padding: "0vh 0vh 1vh 0vh" }}>
          <Button
            variant="success"
            onClick={() =>
              this.props.history.push({
                pathname: "/AddTickets",
              })
            }
          >
            New Ticket
          </Button>
        </ButtonToolbar> */}
        <Paper style={{ padding: "3vh 2vh" }}>
          <div
            style={{
              display: this.state.viewAddons === true ? "none" : "initial",
            }}
          >
            <div className="row">
              <div className="col">
                <label htmlFor="search">Subject: </label>
                <input
                  id="search"
                  placeholder="Search..."
                  className="form-control"
                  type="text"
                  onChange={(e) => {
                    this.setState({ subject_contains: e.target.value });
                    if (this.state.status === "all open") {
                      let url = `?status__not=closed&subject_contains=${e.target.value}&start_date=${this.state.start_date}&end_date=${this.state.end_date}`;
                      this.filter(url);
                    } else {
                      let url = `?status=${this.state.status}&subject_contains=${e.target.value}&start_date=${this.state.start_date}&end_date=${this.state.end_date}`;
                      this.filter(url);
                    }
                    //
                  }}
                />
              </div>
              <div className="col">
                <label htmlFor="recentDate">Recent Date: </label>
                <input
                  id="recentDate"
                  className="form-control"
                  type="date"
                  onChange={(e) => {
                    this.setState({ start_date: e.target.value });
                    if (this.state.status === "all open") {
                      let url = `?status__not=closed&subject_contains=${this.state.subject_contains}&start_date=${e.target.value}&end_date=${this.state.end_date}`;
                      this.filter(url);
                    } else {
                      let url = `?status=${this.state.status}&subject_contains=${this.state.subject_contains}&start_date=${e.target.value}&end_date=${this.state.end_date}`;
                      this.filter(url);
                    }
                  }}
                />
              </div>
              <div className="col">
                <label htmlFor="oldestDate">Oldest Date: </label>
                <input
                  id="oldestDate"
                  className="form-control"
                  type="date"
                  onChange={(e) => {
                    this.setState({ end_date: e.target.value });
                    if (this.state.status === "all open") {
                      let url = `?status__not=closed&subject_contains=${this.state.subject_contains}&start_date=${this.state.start_date}&end_date=${e.target.value}`;
                      this.filter(url);
                    } else {
                      let url = `?status=${this.state.status}&subject_contains=${this.state.subject_contains}&start_date=${this.state.start_date}&end_date=${e.target.value}`;
                      this.filter(url);
                    }
                  }}
                />
              </div>
              <div className="col">
                <label htmlFor="state">State: </label>
                <select
                  id="state"
                  className="form-control"
                  onChange={(e) => {
                    this.setState({ status: e.target.value });
                    if (e.target.value === "all open") {
                      let url = `?status__not=closed&subject_contains=${this.state.subject_contains}&start_date=${this.state.start_date}&end_date=${this.state.end_date}`;
                      this.filter(url);
                    } else {
                      let url = `?status=${e.target.value}&subject_contains=${this.state.subject_contains}&start_date=${this.state.start_date}&end_date=${this.state.end_date}`;
                      this.filter(url);
                    }
                  }}
                >
                  {this.state.states.map((element, i) => (
                    <option key={i} value={element}>
                      {element}
                    </option>
                  ))}
                </select>
              </div>
              <div className="col"></div>
              <div className="col"></div>
            </div>

            <Table hover responsive="md">
              <thead>
                <tr>
                  <th>Keys</th>
                  <th>Status</th>
                  <th>Subject</th>
                  <th>Created</th>
                  <th>Assigned User</th>
                  <th>Assigned Tech</th>
                  <th>Accounts</th>
                  <th style={{ textAlign: "center" }}>Actions</th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((element, i) => (
                  <tr key={i}>
                    <td>{element.pk}</td>
                    <td>{element.status}</td>
                    <td>{element.subject}</td>
                    <td>{DateUtcToLocal(element.created)}</td>
                    <td>{element.assigned_user === null ? "null" : element.assigned_user.first_name + " " + element.assigned_user.last_name}</td>
                    <td>{element.assigned_tech === null ? "null" : element.assigned_tech.first_name + " " + element.assigned_tech.last_name}</td>
                    <td>
                      {element.account.name === undefined ? (
                        "There is no associated account"
                      ) : (
                        <Link
                          to={{
                            pathname: "/account_details",
                            search:
                              "?accountName=" +
                              element.account.name.replace("#", "%23").replace("&", "%26") +
                              "&pk=" +
                              element.account_id,
                          }}
                        >
                          {element.account.name}
                        </Link>
                      )}
                    </td>
                    <td style={{ display: "flex", justifyContent: "center" }}>
                      <Dropdown>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic">
                          Actions
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                          <Dropdown.Item
                            onClick={() => {
                              this.props.history.push({
                                pathname: "/EventTickets",
                                search:
                                  "?pk=" +
                                  element.pk +
                                  "&status=" +
                                  element.status +
                                  "&account_id=" +
                                  element.account_id +
                                  "&subject=" +
                                  element.subject +
                                  "&pkTicket=" +
                                  element.pk,
                              });
                            }}
                          >
                            Events
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              this.setState({
                                DescriptionModal: true,
                                html: element.description,
                              });
                            }}
                          >
                            View Description
                          </Dropdown.Item>
                          <Dropdown.Item
                            onClick={() => {
                              this.setState({
                                Edit: true,
                                element: element,
                              });
                            }}
                          >
                            Edit
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Paper>
        <br />
        <br />
        <br />
      </>
    );
  }
}