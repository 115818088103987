export default {
  header: {
    self: {},
    items: [
      {
        title: "Dashboards",
        root: true,
        alignment: "left",
        toggle: "click",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        submenu: [
          {
            title: "Dashboard Home",
            bullet: "dot",
            page: "dashboard",
          },
          {
            title: "Dashboard Billing",
            bullet: "dot",
            page: "dashboardBilling",
          },
          {
            title: "Dashboard Tickets",
            bullet: "dot",
            page: "dashboardTickets",
          },
          {
            title: "Dashboard Orders",
            bullet: "dot",
            page: "dashboardOrders",
          },
          {
            title: "Dashboard Health Checks",
            bullet: "dot",
            page: "dashboardHealthChecks",
          },
        ],
      },
      {
        title: "Customer Lookup",
        root: true,
        alignment: "left",
        page: "Account_Name",
        toggle: "click",
        translate: "MENU.Customer_Lookup",
        submenu: [
          {
            title: "By Account Name",
            bullet: "dot",
            page: "Account_Name",
          },
          {
            title: "By Phone Number",
            bullet: "dot",
            page: "Phone_Number",
          },
          {
            title: "By Account Number",
            bullet: "dot",
            page: "Account_Number",
          },
          {
            title: "By Service Address",
            bullet: "dot",
            page: "Service_Address",
          },
          {
            title: "By Contact Email",
            bullet: "dot",
            page: "By_Contact_Email",
          },
        ],
      },
      {
        title: "Administration",
        root: true,
        alignment: "left",
        page: "#",
        toggle: "click",
        translate: "MENU.Customer_Lookup",
        submenu: [
          {
            title: "Accounts",
            bullet: "dot",
            page: "ViewAccounts",
            submenu: [
              {
                title: "View Accounts",
                bullet: "dot",
                page: "ViewAccounts",
              },
              {
                title: "Add Accounts",
                bullet: "dot",
                page: "Newquote",
              },
            ],
          },
          {
            title: "Agents",
            bullet: "dot",
            page: "#",
            submenu: [
              {
                title: "View Agents",
                bullet: "dot",
                page: "ViewAgents",
              },
              {
                title: "Add Agent",
                bullet: "dot",
                page: "AddAgents",
              },
            ],
          },
          {
            title: "Contacts",
            bullet: "dot",
            page: "#",
            submenu: [
              {
                title: "View Contacts",
                bullet: "dot",
                page: "ViewContacts",
              },
              {
                title: "Add Contacts",
                bullet: "dot",
                page: "AddContacts",
              },
              // {
              //   title: "Export",
              //   bullet: "dot",
              //   page: "ExportContacts",
              // },
            ],
          },
          {
            title: "Form 477 Generator",
            bullet: "dot",
            page: "Form_447_generator",
          },

          {
            title: "Leads",
            bullet: "dot",
            page: "ViewLeads",
            submenu: [
              {
                title: "View Leads",
                bullet: "dot",
                page: "ViewLeads",
              },
              {
                title: "Add Leads",
                bullet: "dot",
                page: "AddLeads",
              },
            ],
          },
          {
            title: "Products",
            bullet: "dot",
            page: "ViewProducts",
            submenu: [
              {
                title: "View Products",
                bullet: "dot",
                page: "ViewProducts",
              },
              {
                title: "Add Products",
                bullet: "dot",
                page: "AddProducts",
              },
            ],
          },
          {
            title: "Products Key",
            bullet: "dot",
            page: "ViewProductsKeys",
            submenu: [
              {
                title: "View Products Keys",
                bullet: "dot",
                page: "ViewProductsKeys",
              },
              {
                title: "Add Products Keys",
                bullet: "dot",
                page: "AddProductsKeys",
              },
            ],
          },
          {
            title: "ITP Voice",
            bullet: "dot",
            page: "#",
            submenu: [
              {
                title: "SBC",
                bullet: "dot",
                page: "#",
                submenu: [
                  {
                    title: "Carriers",
                    bullet: "dot",
                    page: "Carriers",
                  },
                  {
                    title: "CDR",
                    bullet: "dot",
                    page: "CDR",
                  },

                  {
                    title: " Customer IPs",
                    bullet: "dot",
                    page: "CustomerIPs",
                  },
                  {
                    title: "DIDs",
                    bullet: "dot",
                    page: "DIDs",
                  },
                  {
                    title: "DID Destination Groups",
                    bullet: "dot",
                    page: "DIDDestinationGroups",
                  },
                  {
                    title: "Rates",
                    bullet: "dot",
                    page: "Rates",
                  },
                  {
                    title: "International Rates",
                    bullet: "dot",
                    page: "InternationalRates",
                  },
                ],
              },
              {
                title: "Voice Apps",
                bullet: "dot",
                page: "ViewVoiceApps",
                submenu: [
                  {
                    title: "View Apps",
                    bullet: "dot",
                    page: "ViewVoiceApps",
                  },
                  {
                    title: "Add New App",
                    bullet: "dot",
                    page: "AddVoiceApp",
                  },
                ],
              },
              /* {
                title: "Asterisk Queue servers",
                bullet: "dot",
                page: "asterik_queues_server",
              } */
            ],
          },
          {
            title: "Service Addresses",
            bullet: "dot",
            page: "#",
            submenu: [
              {
                title: "Add New",
                bullet: "dot",
                page: "ServiceAddressesNew",
              },
              {
                title: "Search",
                bullet: "dot",
                page: "ServiceAddressesSearch",
              },
            ],
          },
          {
            title: "Tickets",
            bullet: "dot",
            page: "ViewTickets",
            submenu: [
              {
                title: "View Tickets",
                bullet: "dot",
                page: "ViewTickets",
              },
              {
                title: "Add Tickets",
                bullet: "dot",
                page: "AddTickets",
              },
              {
                title: "Settings",
                bullet: "dot",
                page: "AddTickets",
                submenu: [
                  {
                    title: "General",
                    bullet: "dot",
                    page: "General",
                  },
                  {
                    title: "Black List Settings",
                    bullet: "dot",
                    page: "BlackListSettings",
                  },
                ],
              },
            ],
          },

          {
            title: "Users",
            bullet: "dot",
            page: "ViewUsers",
            submenu: [
              {
                title: "View Users",
                bullet: "dot",
                page: "ViewUsers",
              },
              {
                title: "Add Users",
                bullet: "dot",
                page: "AddUsers",
              },
            ],
          },
          {
            title: "Form W9 Generator",
            bullet: "dot",
            page: "Form_W9_generator",
          },
        ],
      },
      {
        title: "Calendar",
        root: true,
        alignment: "left",
        page: "calendar",
        toggle: "click",
        translate: "MENU.Customer_Lookup",
      },
    ],
  },
  aside: {
    self: {},
    items: [
      {
        title: "Dashboard",
        root: true,
        icon: "flaticon2-architecture-and-city",
        page: "dashboard",
        translate: "MENU.DASHBOARD",
        bullet: "dot",
      },
      {
        title: "New Quote",
        root: true,
        // icon: "flaticon2-architecture-and-city",
        page: "Newquote",
        translate: "MENU.Newquote",
        bullet: "dot",
      },
      {
        title: "Customer Lookup",
        root: true,
        page: "Account_Name",
        translate: "MENU.Customer_Lookup",
        submenu: [
          {
            title: "Account details",
            root: true,
            page: "account_details",
            translate: "MENU.account_details",
          },
          {
            title: "Account details",
            root: true,
            page: "account_details",
            translate: "MENU.account_details",
            submenu: [
              {
                title: "Products",
                page: "products",
              },
              {
                title: "Quotes",
                page: "quotes",
              },
              {
                title: "Quotes",
                page: "quotes",
                submenu: [
                  {
                    title: "New Quote",
                    page: "new_quote",
                  },
                ],
              },
              {
                title: "Edit account",
                page: "editAccount",
              },
              {
                title: "Orders",
                page: "orders",
              },

              {
                title: "Orders",
                page: "orders",
                submenu: [
                  {
                    title: "Orders Items",
                    page: "orderitems",
                  },
                ],
              },
              {
                title: "Invoices",
                page: "invoices",
              },
              {
                title: "Payments",
                page: "payments",
              },
              {
                title: "History",
                page: "history",
              },
              {
                title: "Contacts",
                page: "contacts",
              },
              {
                title: "Assets",
                page: "AssetTracking",
              },
            ],
          },
          {
            title: "My Profile",
            root: true,
            page: "myprofile",
            translate: "MENU.myprofile",
          },
          {
            title: "My Profile",
            root: true,
            page: "myprofile",
            translate: "MENU.myprofile",
            submenu: [],
          },
          // {
          //   title: "New Quote",
          //   root: true,
          //   page: "Newquote",
          //   translate: "MENU.Newquote",
          // },
          // {
          //   title: "New Quote",
          //   root: true,
          //   page: "Newquote",
          //   translate: "MENU.Newquote",
          //   submenu: [],
          // },
        ],
      },

      {
        title: "Administration",
        root: true,
        alignment: "left",
        page: "#",
        toggle: "click",
        translate: "MENU.Customer_Lookup",
        submenu: [
          {
            title: "Service Addresses",
            root: true,
            bullet: "dot",
            page: "ServiceAddressesNew",
            submenu: [
              {
                title: "Add New",
                root: true,
                // bullet: "dot",
                page: "ServiceAddressesNew",
                submenu: [
                  {
                    title: "Add Services To Address",
                    root: true,
                    page: "AddServicesToAddress",
                    translate: "MENU.AddServicesToAddress",
                  },
                ],
              },
            ],
          },
          {
            title: "Agents",
            root: true,
            bullet: "dot",
            page: "ViewAgents",
            submenu: [
              {
                title: "View Agents",
                root: true,
                // bullet: "dot",
                page: "ViewAgents",
                submenu: [
                  {
                    title: "Edit Agents",
                    root: true,
                    page: "EditAgents",
                    translate: "MENU.EditAgents",
                  },
                ],
              },
            ],
          },
          {
            title: "View Tickets",
            root: true,
            bullet: "dot",
            page: "ViewTickets",
            submenu: [
              {
                title: "Event Tickets",
                root: true,
                // bullet: "dot",
                page: "EventTickets",
              },
            ],
          },
        ],
      },
    ],
  },
};
