import React from "react";
import PropTypes from "prop-types";
import deburr from "lodash/deburr";
import Downshift from "downshift";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Popper from "@material-ui/core/Popper";
import Paper from "@material-ui/core/Paper";
import MenuItem from "@material-ui/core/MenuItem";
import Chip from "@material-ui/core/Chip";
import petition_user from "../../../../../petitions/petition_user"



const info = [];
const suggestions = [];

var enabled = false;
var lastValue = "";

function renderInput(inputProps, val) {
  const { InputProps, classes, ref, ...other } = inputProps;
  for (let i = 0; i < suggestions.length; i++) {
    if (inputProps.inputProps.value !== lastValue) {
      if (inputProps.inputProps.value === suggestions[i].label) {
        if (!enabled) {
          lastValue = inputProps.inputProps.value;
          enabled = true;
          val(
            info.find(
              (element) =>
                element.first_name + " " + element.last_name ===
                inputProps.inputProps.value
            )
          );
        }
      } else {
        enabled = false;
      }
    }
  }

  async function editsuggestions(e) {}

  return (
    <TextField
      id="searchtextbox"
      onChange={editsuggestions}
      InputLabelProps={other.InputLabelProps}
      inputProps={other.inputProps}
      variant="outlined"
      margin="dense"
      fullWidth
    />
  );
}

function renderSuggestion(suggestionProps) {
  const {
    suggestion,
    index,
    itemProps,
    highlightedIndex,
    selectedItem,
  } = suggestionProps;
  const isHighlighted = highlightedIndex === index;
  const isSelected = (selectedItem || "").indexOf(suggestion.label) > -1;

  return (
    <MenuItem
      {...itemProps}
      key={suggestion.label}
      selected={isHighlighted}
      component="div"
      style={{
        fontWeight: isSelected ? 500 : 400,
      }}
    >
      {suggestion.label}
    </MenuItem>
  );
}
renderSuggestion.propTypes = {
  highlightedIndex: PropTypes.number,
  index: PropTypes.number,
  itemProps: PropTypes.object,
  selectedItem: PropTypes.string,
  suggestion: PropTypes.shape({ label: PropTypes.string }).isRequired,
};

function getSuggestions(value, { showEmpty = false } = {}) {
  const inputValue = deburr(value.trim()).toLowerCase();
  const inputLength = inputValue.length;

  petition_user("users-search", inputValue)
    .then(({ data: { result } }) => {
      suggestions.length = 0;
      info.length = 0;
      for (var i = 0; i < result.length; i++) {
        if (i === 0) {
          suggestions.push({
            label: result[i].first_name + " " + result[i].last_name,
          });
          info.push(result[i]);
        } else {
          if (result[i].pk === result[i - 1].pk) {
          } else {
            suggestions.push({
              label: result[i].first_name + " " + result[i].last_name,
            });
            info.push(result[i]);
          }
        }
      }
    })
    .catch((error) => {
      console.log(error);
    });

  let count = 0;

  return inputLength === 0 && !showEmpty
    ? []
    : suggestions.filter((suggestion) => {
        const keep =
          count < 5 &&
          suggestion.label.slice(0, inputLength).toLowerCase() === inputValue;

        if (keep) {
          count += 1;
        }
        return keep;
      });
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    height: 50,
  },
  container: {
    flexGrow: 1,
    position: "relative",
  },
  paper: {
    position: "absolute",
    zIndex: 1,
    marginTop: theme.spacing(1),
    left: 0,
    right: 0,
  },
  chip: {
    margin: theme.spacing(0.5, 0.25),
  },
  inputRoot: {
    flexWrap: "wrap",
  },
  inputInput: {
    width: "auto",
    flexGrow: 1,
  },
  divider: {
    height: theme.spacing(2),
  },
}));

let popperNode;

export default function AutoCompleteUsers(props) {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Downshift id="downshift-simple">
        {({
          getInputProps,
          getItemProps,
          getLabelProps,
          getMenuProps,
          highlightedIndex,
          inputValue,
          isOpen,
          selectedItem,
        }) => {
          const { onBlur, onFocus, ...inputProps } = getInputProps({
            placeholder: "",
          });

          return (
            <div className={classes.container}>
              {renderInput(
                {
                  fullWidth: true,
                  classes,
                  label:
                    "Please enter the customers address where services will be installed/delivered",
                  InputLabelProps: getLabelProps({ shrink: true }),
                  InputProps: { onBlur, onFocus },
                  inputProps,
                },
                props.val
              )}

              <div {...getMenuProps()}>
                {isOpen ? (
                  <Paper className={classes.paper} square>
                    {getSuggestions(inputValue).map((suggestion, index) =>
                      renderSuggestion({
                        suggestion,
                        index,
                        itemProps: getItemProps({ item: suggestion.label }),
                        highlightedIndex,
                        selectedItem,
                      })
                    )}
                  </Paper>
                ) : null}
              </div>
            </div>
          );
        }}
      </Downshift>
      <div className={classes.divider} />
      <div className={classes.divider} />
    </div>
  );
}
