import React, { useMemo, useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Portlet,
  PortletBody,
  PortletHeader,
  PortletHeaderToolbar,
} from "../../../../partials/content/Portlet";
import { metronic } from "../../../../../_metronic";
import QuickStatsChart from "../../../../widgets/QuickStatsChart";
import OrderStatisticsChart from "../../../../widgetsITP/OrderStatisticsChart";
import Graphics from "../../../../widgetsITP/Graphics/Graphics";
import OrdersWidget from "../../../../widgets/OrdersWidget";
import SalesBarChart from "../../../../widgets/SalesBarChart";
import DownloadFiles from "../../../../widgets/DownloadFiles";
import NewUsers from "../../../../widgetsITP/NewUsers";
import LatestUpdates from "../../../../widgets/LatestUpdates";
import BestSellers from "../../../../widgets/BestSellers";
import RecentActivities from "../../../../widgets/RecentActivities";
import PortletHeaderDropdown from "../../../../partials/content/CustomDropdowns/PortletHeaderDropdown";
import petition_user from "../../petitions/petition_user";
import CurrencyFormat from "react-currency-format";
import ChangeDateModal from "../../../../widgetsITP/modals/ChangeDateModal";
import PortletHeaderDropdownToggle from "../../../../widgetsITP/PortletHeaderDropdownToggle/PortletHeaderDropdownToggle";
import Paper from "@material-ui/core/Paper";
import Pagination from "react-pagination-js";
import "../../../../css/pagination.css";
import { Table } from "react-bootstrap";
import { Link } from "react-router-dom";
import LoadingModalMessage from "../../utils/modalloadingmessage";
import Loading from "../../Loading/Loading";

// import petition_user from "./Customer_Lookup/account_details/petition_user";

// petition_user("profile", "")
//   .then(({ data: { result } }) => {
//     console.log(Object.values(result));
//     localStorage.setItem("profile", result.first_name);
//   })
//   .catch((error) => {
//     console.log(error);
//   });

export default function DashboardBilling(props) {
  const { brandColor, dangerColor, successColor, primaryColor } = useSelector(
    (state) => ({
      brandColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.brand"
      ),
      dangerColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.danger"
      ),
      successColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.success"
      ),
      primaryColor: metronic.builder.selectors.getConfig(
        state,
        "colors.state.primary"
      ),
    })
  );
  const [didMount, setDidMount] = useState(false);

  const [billingPayments, setbillingPayments] = useState({});
  const [beforeBillingPayments, setBeforeBillingPayments] = useState({});

  const [months, setMonths] = useState([]);
  const [monthsValues, setMonthsValues] = useState([]);

  const [monthsInvoice, setMonthsInvoice] = useState([]);
  const [monthsValuesInvoice, setMonthsValuesInvoice] = useState([]);
  // const [years, setMonthsValues] = useState({year1:new Date().getFullYear(),year2:new Date().getFullYear()});
  const [year1, setyear1] = useState(new Date().getFullYear());
  const [year2, setyear2] = useState(new Date().getFullYear());

  const [loadingBillingPayments, setLoadingBillingPayments] = useState(false);
  const [loadingAccountsInactive, setLoadingAccountsInactive] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [totalPage, setTotalPages] = useState(0);
  const [inactiveAccounts, setInactiveAccounts] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [loading, setLoading] = useState(false);
  const [dataInvoices, setDataInvoices] = useState([]);

  const [showChangeDateModal, setShowChangeDateModal] = useState(false);

  const [timeframe, setTimeframe] = useState({
    timeStart: "",
    timeEnd: "",
  });

  const requestapi = async () => {
    setLoadingBillingPayments(true);
    await petition_user("billingPayments", { year1: year2, year2: year1 })
      .then(({ data: { result } }) => {
        setbillingPayments(result);
        setMonths(result.payment_stats.month_stats.months);
        setMonthsValues(result.payment_stats.month_stats.totals);
        setMonthsInvoice(result.invoice_stats.month_stats.months);
        setMonthsValuesInvoice(result.invoice_stats.month_stats.totals);
        setyear1(new Date().getFullYear());
        setyear2(new Date().getFullYear());
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingBillingPayments(false);
      });
  };

  const getAccountsInactive = async (page) => {
    setLoadingAccountsInactive(true);
    await petition_user("getAccountsInactive", `&unlimit=true`)
      .then(({ data: { result } }) => {
        setInactiveAccounts(result.model_list);
        setCurrentPage(result.page + 1);
        setTotalPages(result.total_pages);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoadingAccountsInactive(false);
      });
  };

  const onChangeDate = (e) => {
    if (e.target.name === "timeStart") {
      let newTimeEnd;
      if (
        e.target.value.split("-")[1] === timeframe.timeEnd.split("-")[1] &&
        parseInt(e.target.value.split("-")[2]) >
          parseInt(timeframe.timeEnd.split("-")[2])
      ) {
        newTimeEnd = e.target.value;
      } else if (
        parseInt(e.target.value.split("-")[1]) >
        parseInt(timeframe.timeEnd.split("-")[1])
      ) {
        newTimeEnd = e.target.value;
      } else {
        newTimeEnd = timeframe.timeEnd;
      }

      setTimeframe({
        ...timeframe,
        [e.target.name]: e.target.value,
        timeEnd: newTimeEnd,
        minTimeframeEnd: e.target.value,
      });
    } else {
      setTimeframe({ ...timeframe, [e.target.name]: e.target.value });
    }

    setDisabled(false);
  };

  const getInvoices = (init, end) => {
    setLoading(true);
    petition_user("getInvoices", {
      start_date: init,
      end_date: end,
    })
      .then(({ data: result }) => {
        setDisabled(true);
        setLoading(false);
        setDataInvoices(result.result);
      })
      .catch((error) => console.log(error));
  };

  useEffect(() => {
    requestapi();
    getAccountsInactive(1);

    const dateTimeframe = new Date();

    const getTimeStart = `${
      dateTimeframe.getMonth() + 1 === 1
        ? dateTimeframe.getFullYear() - 1
        : dateTimeframe.getFullYear()
    }-${
      dateTimeframe.getMonth() === 0
        ? "12"
        : dateTimeframe.getMonth() === 1
        ? "01"
        : dateTimeframe.getMonth() > 1 && dateTimeframe.getMonth() < 9
        ? `0${dateTimeframe.getMonth() + 1}`
        : `${dateTimeframe.getMonth() + 1}`
    }-${
      dateTimeframe.getDate() < 10
        ? `0${dateTimeframe.getDate()}`
        : `${dateTimeframe.getDate()}`
    }`;

    const getTimeEnd = `${dateTimeframe.getFullYear()}-${
      dateTimeframe.getMonth() + 1 < 10
        ? `0${dateTimeframe.getMonth() + 1}`
        : `${dateTimeframe.getMonth() + 1}`
    }-${
      dateTimeframe.getDate() < 10
        ? `0${dateTimeframe.getDate()}`
        : `${dateTimeframe.getDate()}`
    }`;

    setTimeframe({
      ...timeframe,
      timeStart: getTimeStart,
      timeEnd: getTimeEnd,
    });

    getInvoices(getTimeStart, getTimeEnd);
  }, []);

  const chartOptions = useMemo(
    () => ({
      chart1: {
        data: [10, 14, 18, 11, 9, 12, 14, 17, 18, 14],
        color: brandColor,
        border: 3,
      },

      chart2: {
        data: [11, 12, 18, 13, 11, 12, 15, 13, 19, 15],
        color: dangerColor,
        border: 3,
      },

      chart3: {
        data: [12, 12, 18, 11, 15, 12, 13, 16, 11, 18],
        color: successColor,
        border: 3,
      },

      chart4: {
        data: [11, 9, 13, 18, 13, 15, 14, 13, 18, 15],
        color: primaryColor,
        border: 3,
      },
    }),
    [brandColor, dangerColor, primaryColor, successColor]
  );

  const LoadingModalMessageComponent = () => {
    if (loadingAccountsInactive === true || loadingBillingPayments === true) {
      return (
        <LoadingModalMessage
          show={true}
          spinner={true}
          tittleMessage={""}
          message={""}
          onHide={() => {}}
        />
      );
    } else return <> </>;
  };

  return (
    <>
      <div className="row">
        <LoadingModalMessageComponent />
        <div className="col-sm-12 col-md-12 col-lg-6">
          <Portlet fluidHeight={true}>
            <PortletHeader
              title="Order Statistics"
              toolbar={
                <PortletHeaderToolbar>
                  <PortletHeaderDropdownToggle
                    action={setShowChangeDateModal}
                  />
                </PortletHeaderToolbar>
              }
            />
            <PortletBody>
              <OrderStatisticsChart
                monthsValuesInvoice={monthsValuesInvoice}
                monthsInvoice={monthsInvoice}
                months={months}
                monthsValues={monthsValues}
                max={billingPayments.max}
                billingPayments={billingPayments}
              />
            </PortletBody>
          </Portlet>
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          {/* <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand" > 
            <PortletBody fluidHeight={true}> */}
          <Graphics
            months={months}
            monthsValues={monthsValues}
            title={"Payments Stats"}
          />
          {/* </PortletBody>
          </Portlet> */}
        </div>
        <div className="col-sm-12 col-md-12 col-lg-6">
          {/* <Portlet className="kt-portlet--height-fluid-half kt-portlet--border-bottom-brand">
            <PortletBody> */}
          {/* <h1>Invoice Stats</h1> */}
          <Graphics
            months={months}
            monthsValues={monthsValuesInvoice}
            title={"Invoice Stats"}
          />
          {/* </PortletBody> */}
          {/* </Portlet> */}
        </div>
      </div>

      <Paper style={{ padding: "3vh 2vh" }}>
        <div>
          <Table hover responsive="md" size="sm">
            <thead>
              <tr>
                <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                  Name
                </th>
                <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                  Balance
                </th>
                <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                  Past due balance
                </th>
              </tr>
            </thead>
            <tbody>
              {inactiveAccounts.map((element, i) => (
                <tr key={i}>
                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                    <Link
                      to={{
                        pathname: "/account_details",
                        search:
                          "?accountName=" +
                          element.name.replace("#", "%23").replace("&", "%26") +
                          "&pk=" +
                          element.pk,
                      }}
                    >
                      {element.name}
                    </Link>
                  </td>
                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {element.billing_api.balance}
                  </td>
                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {element.billing_api.past_due_balance}
                  </td>
                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {element.billing_api.past_due_balance}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
          {/*    <div>
            <Pagination
              currentPage={currentPage}
              totalSize={(totalPage + 1) * 10}
              sizePerPage={10}
              totalPages={(totalPage + 1) * 10}
              changeCurrentPage={getAccountsInactive}
              theme="bootstrap"
            />
          </div> */}
        </div>
      </Paper>

      <Paper style={{ padding: "3vh 2vh", marginTop: "20px" }}>
        <div>
          <h3>Select Timeframe</h3>

          <div className="mt-3">
            <label htmlFor="">Start Date:</label>

            <input
              type="date"
              name="timeStart"
              onChange={onChangeDate}
              value={timeframe.timeStart}
              className="form-control"
              id=""
            />
          </div>
          <div className="mt-3">
            <label htmlFor="">End Date:</label>

            <input
              type="date"
              name="timeEnd"
              onChange={onChangeDate}
              value={timeframe.timeEnd}
              className="form-control"
              id=""
            />
          </div>
        </div>
        <button
          type="button"
          className="btn btn-success btn-sm btn-bold btn-font-md mt-3"
          disabled={disabled}
          onClick={() => getInvoices(timeframe.timeStart, timeframe.timeEnd)}
        >
          Change Timeframe
        </button>
      </Paper>

      {loading ? (
        <Loading />
      ) : (
        <Paper style={{ padding: "3vh 2vh", marginTop: "20px" }}>
          <div>
            <Table hover responsive="md" size="sm">
              <thead>
                <tr>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    Name
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    Created
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    Due date
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    Balance
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    Past due balance
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    Total invoice
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    Billing cycle start date
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    Billing cycle end date
                  </th>
                  <th style={{ verticalAlign: "middle", textAlign: "center" }}>
                    CRM account number
                  </th>
                </tr>
              </thead>
              <tbody>
                {dataInvoices.map((element, i) => (
                  <tr key={i}>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.name}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.created}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.due_date}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.balance}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.past_due_balance}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.total_invoice}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.billing_cycle_start_date}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.billing_cycle_end_date}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {element.crm_account_number}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </Paper>
      )}

      {showChangeDateModal && (
        <ChangeDateModal
          show={showChangeDateModal}
          onHide={setShowChangeDateModal}
          setyear1={setyear1}
          setyear2={setyear2}
          requestapi={requestapi}
        />
      )}
    </>
  );
}
