import React, { useState } from "react";
import { Formik } from "formik";
import { Redirect } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { FormattedMessage, injectIntl } from "react-intl";
import { Checkbox, FormControlLabel, TextField } from "@material-ui/core";
import * as auth from "../../store/ducks/auth.duck";
// import { register } from "../../crud/auth.crud";
import changePassword from "./resetPasswordPetition";
import { Modal,Spinner,Button } from "react-bootstrap";
import * as qs from "query-string";
function seesucess(val){
  if(val){
    return "initial"
  }else{
    return "none"
  }
}

export default function ResetPassword(props) {
  const { intl } = props;
  const parsed = qs.parse(props.location.search);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [message, setMessage] = useState(""); 
  const [tittleMessage, setTittleMessage] = useState("");
  const [success, setSuccess] = useState(false);
  const [loadingButtonStyle, setLoadingButtonStyle] = useState({
    paddingRight: "2.5rem",
  });
  const enableLoading = () => {
    setLoading(true);
    setLoadingButtonStyle({ paddingRight: "3.5rem" });
  };

  const disableLoading = () => {
    setLoading(false);
    setLoadingButtonStyle({ paddingRight: "2.5rem" });
  };
  // console.log(parsed.token);
  if (parsed.token) {
    return (
      <>
      <Modal
        show={modal}
        // size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
           <p>{tittleMessage}</p> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display:"flex", justifyContent:"center" }}>        
        <h4>{message}</h4>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{display:seesucess(!success)}} onClick={()=> setModal(false)}>Close</Button>
          <Button style={{display:seesucess(success)}} onClick={()=> props.history.push({pathname:"/auth/login"})}>Close</Button>
        </Modal.Footer>
      </Modal>  

      <div className="kt-login__body">
        <div className="kt-login__form">
          <div className="kt-login__title">
            <h3>Reset Password</h3>
          </div>

          <Formik
            initialValues={{
              password: "",
              confirmPassword: "",
            }}
            validate={(values) => {
              const errors = {};

              if (!values.password) {
                errors.password = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              }

              if (!values.confirmPassword) {
                errors.confirmPassword = intl.formatMessage({
                  id: "AUTH.VALIDATION.REQUIRED_FIELD",
                });
              } else if (values.password !== values.confirmPassword) {
                errors.confirmPassword =
                  "Password and Confirm Password didn't match.";
              }

              return errors;
            }}
            onSubmit={(values, { setStatus, setSubmitting }) => {
              enableLoading();
              changePassword(parsed.token, values.password)
              .then(({ data: { result } }) => {
                disableLoading();
                setModal(true);
                setSuccess(true);
                setMessage("Success");
                setTittleMessage("Your password has been changed");
                console.log(result);
                // props.history.push({
                //     pathname: "/auth/login",
                //   });
              })
              .catch((error) => {
                console.log(error);
                disableLoading();
                setSubmitting(false);
                setModal(true);
                setSuccess(false);
                setMessage("Error changing password");
                setTittleMessage("Failed");
                // setStatus(
                //   intl.formatMessage({
                //     id: "AUTH.VALIDATION.REQUIRED_FIELD"
                //   })
                // );
              });
            }}
          >
            {({
              values,
              status,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
            }) => (
              <form onSubmit={handleSubmit} noValidate autoComplete="off">
                {status && (
                  <div role="alert" className="alert alert-danger">
                    <div className="alert-text">{status}</div>
                  </div>
                )}

                <div className="form-group mb-0">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Password"
                    className="kt-width-full"
                    name="password"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.password}
                    helperText={touched.password && errors.password}
                    error={Boolean(touched.password && errors.password)}
                  />
                </div>

                <div className="form-group">
                  <TextField
                    type="password"
                    margin="normal"
                    label="Confirm Password"
                    className="kt-width-full"
                    name="confirmPassword"
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={values.confirmPassword}
                    helperText={
                      touched.confirmPassword && errors.confirmPassword
                    }
                    error={Boolean(
                      touched.confirmPassword && errors.confirmPassword
                    )}
                  />
                </div>

                <div className="kt-login__actions">
                  <button
                    disabled={isSubmitting}
                    className={`btn btn-primary btn-elevate kt-login__btn-primary ${clsx(
                      {
                        "kt-spinner kt-spinner--right kt-spinner--md kt-spinner--light": loading,
                      }
                    )}`}
                    style={loadingButtonStyle}
                  >
                    Submit
                  </button>
                </div>
              </form>
            )}
          </Formik>
        </div>
      </div>
      </>
    );
  } else {
    return (
      <>
        <Redirect from="/auth" exact={true} to="/auth/login" />
        <Redirect to="/auth/login" />
      </>
    );
  }
}
