import React from "react";
import { Modal,Spinner } from "react-bootstrap";
export default function Await() {
    return (
      <>
        <Modal
        show= {true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
          Loading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display:"flex", justifyContent:"center" }}>
          <Spinner animation="grow" style={{marginTop:"10vh",marginBottom:"10vh",height: "10vh", width: "10vh" }}/>
        </Modal.Body>
        <Modal.Footer>
        </Modal.Footer>
      </Modal>
      </>
    );
  }
  
