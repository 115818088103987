import React, { Suspense, lazy } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import Builder from "./Builder";
import Dashboard from "./Dashboard";
import DashboardBilling from "./Dashboard/DashboardBilling/DashboardBilling";
import DashboardTickets from "./Dashboard/DashboardTickets/DashboardTickets";
import DashboardOrders from "./Dashboard/DashboardOrders/DashboardOrders";
import DashboardHealthChecks from "./Dashboard/DashboardHealthChecks/DashboardHealthChecks";

// import Agent_Details from "agent/Agent_Details";
// import Unpaid_Commissions from "agent/Unpaid_Commissions";
// import Accounts from "./agent/Accounts";
import DocsPage from "./docs/DocsPage";
import { LayoutSplashScreen } from "../../../_metronic";

const GoogleMaterialPage = lazy(() =>
  import("./google-material/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./react-bootstrap/ReactBootstrapPage")
);

const Account_Name = lazy(() => import("./Customer_Lookup/Account_Name"));

const Service_Address = lazy(() => import("./Customer_Lookup/Service_Address"));

const Contacts = lazy(() =>
  import("./Customer_Lookup/account_details/AccountMenu/Contacts/Contacts")
);

const Phone_Number = lazy(() => import("./Customer_Lookup/Phone_Number"));

const Account_Number = lazy(() => import("./Customer_Lookup/Account_Number"));

const By_Contact_Email = lazy(() =>
  import("./Customer_Lookup/By_Contact_Email")
);

const Account_Details = lazy(() =>
  import("./Customer_Lookup/account_details/account_details")
);

const History = lazy(() =>
  import("./Customer_Lookup/account_details/AccountMenu/History/History")
);
const Quotes = lazy(() =>
  import(
    "./Customer_Lookup/account_details/AccountMenu/Quotes/QuoteList/QuotesList"
  )
);
const Orders = lazy(() =>
  import("./Customer_Lookup/account_details/AccountMenu/Orders/Orders")
);
const Invoices = lazy(() =>
  import("./Customer_Lookup/account_details/AccountMenu/Invoices/Invoices")
);
const Payments = lazy(() =>
  import("./Customer_Lookup/account_details/AccountMenu/Payments/Payments")
);

const Products = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/products")
);

const Orderitems = lazy(() =>
  import(
    "./Customer_Lookup/account_details/AccountMenu/Orders/OrderItems/orderitems"
  )
);

const TicketsCoustomer = lazy(() =>
  import(
    "./Customer_Lookup/account_details/TicketsCoustomer/ViewTickets/TicketsCoustomer"
  )
);

// const OrderitemsCopy = lazy(() =>
//   import("./Customer_Lookup/account_details/account_info/orderitemscopy")
// );

const Myprofile = lazy(() => import("./Menu_User/myprofile"));

const New_Quote = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/newquote/newquote")
);

const New_Quote_2 = lazy(() =>
  import(
    "./Customer_Lookup/account_details/AccountMenu/Quotes/QuoteItems/QuoteItems"
  )
);

const AddNewNRC = lazy(() =>
  import("./Customer_Lookup/account_details/account_info/newquote/addnewNRC")
);
const AddnNewService = lazy(() =>
  import(
    "./Customer_Lookup/account_details/account_info/newquote/addnewservice"
  )
);

const Newquote = lazy(() => import("./../submenu/newquote"));

const EditAccount = lazy(() =>
  import(
    "./Customer_Lookup/account_details/AccountMenu/EditAccount/EditAccount"
  )
);

const Form_477_Generator = lazy(() =>
  import("./Administration/Form_447_Generator/Form_447_Generator")
);
const Form_W9_Generator = lazy(() =>
  import("./Administration/Form_W9_Generator/Form_W9_Generator")
);

const ViewAgents = lazy(() =>
  import("./Administration/Agents/ViewAgents/ViewAgents")
);

const EditAgents = lazy(() =>
  import("./Administration/Agents/ViewAgents/Edit/Edit")
);

const AddAgents = lazy(() =>
  import("./Administration/Agents/AddAgents/AddAgents")
);

const AddUsers = lazy(() => import("./Administration/Users/AddUsers/AddUsers"));

const ViewUsers = lazy(() =>
  import("./Administration/Users/ViewUsers/ViewUsers")
);

const EditUsers = lazy(() =>
  import("./Administration/Users/ViewUsers/Edit/Edit")
);

const AgentDetailsPage = lazy(() =>
  import("./Administration/AgentDetailsPage/AgentDetailsPage")
);

const ServiceAddressesSearch = lazy(() =>
  import("./Administration/ServiceAddressesSearch/ServiceAddressesSearch")
);

const ServiceAddressesNew = lazy(() =>
  import("./Administration/ServiceAddressesNew/ServiceAddressesNew")
);
const AddServicesToAddress = lazy(() =>
  import(
    "./Administration/ServiceAddressesNew/AddServicesToAddress/AddServicesToAddress"
  )
);

const ViewProducts = lazy(() =>
  import("./Administration/Products/ViewProducts/ViewProducts")
);

const AddProducts = lazy(() =>
  import("./Administration/Products/AddProducts/AddProducts")
);

const ViewProductsKeys = lazy(() =>
  import("./Administration/ProductsKeys/ViewProductsKeys/ViewProductsKeys")
);

const AddProductsKeys = lazy(() =>
  import("./Administration/ProductsKeys/AddProductsKeys/AddProductsKeys")
);

const ViewTickets = lazy(() =>
  import("./Administration/Tickets/ViewTickets/ViewTickets")
);

const AssetTracking = lazy(() =>
  import("./Administration/AssetTracking/AssetTracking")
);

const AddTickets = lazy(() =>
  import("./Administration/Tickets/AddTickets/AddTickets")
);

const EventTickets = lazy(() =>
  import("./Administration/EventTickets/EventTickets")
);

const ViewContacts = lazy(() =>
  import("./Administration/Contacts/ViewContacts/ViewContacts")
);
const AddContacts = lazy(() =>
  import("./Administration/Contacts/AddContacts/AddContacts")
);

const ViewAccounts = lazy(() =>
  import("./Administration/Accounts/ViewAccounts/ViewAccounts")
);

const ViewLeads = lazy(() =>
  import("./Administration/Leads/ViewLeads/ViewLeads")
);

const AddLeads = lazy(() => import("./Administration/Leads/AddLeads/AddLeads"));

const EditLeads = lazy(() =>
  import("./Administration/Leads/ViewLeads/Edit/EditLeads")
);

const Carriers = lazy(() => import("./Administration/SBC/Carriers/Carriers"));

const CarrierDestinations = lazy(() =>
  import("./Administration/SBC/CarrierDestinations/CarrierDestinations")
);
const CarrierIPs = lazy(() =>
  import("./Administration/SBC/CarrierIPs/CarrierIPs")
);
const CustomerIPs = lazy(() =>
  import("./Administration/SBC/CustomerIPs/CustomerIPs")
);
const DIDDestinationGroups = lazy(() =>
  import("./Administration/SBC/DIDDestinationGroups/DIDDestinationGroups")
);
const DIDs = lazy(() => import("./Administration/SBC/DIDs/DIDs"));
const InternationalRates = lazy(() =>
  import("./Administration/SBC/InternationalRates/InternationalRates")
);
const Rates = lazy(() => import("./Administration/SBC/Rates/Rates"));
const CDR = lazy(() => import("./Administration/SBC/CDR/CDR"));
const General = lazy(() =>
  import("./Administration/Tickets/Settings/General/General")
);
const BlackListSettings = lazy(() =>
  import(
    "./Administration/Tickets/Settings/BlackListSettings/BlackListSettings"
  )
);

const ViewVoiceApp = lazy(() =>
  import("./Administration/VoiceApp/ViewVoiceApp/ViewVoiceApp")
);

const AddVoiceApp = lazy(() =>
  import("./Administration/VoiceApp/AddVoiceApp/AddVoiceApp")
);

const Prueba = lazy(() =>
  import("./Pruebas/ServiceAddressesNew/ServiceAddressesNew")
);

const Calendar = lazy(() => import("./Calendar/Calendar"));

const AsterikQueues = lazy(() => import("./AsterikQueues/AsterikQueues"));

export default function HomePage() {
  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <Switch>
        {
          /* Redirect from root URL to /dashboard. */
          <Redirect exact from="/" to="/dashboard" />
        }

        <Route path="/calendar" component={Calendar} />
        <Route path="/asterik_queues_server" component={AsterikQueues} />

        <Route path="/builder" component={Builder} />
        <Route path="/dashboard" component={Dashboard} />

        <Route path="/dashboardBilling" component={DashboardBilling} />
        <Route path="/dashboardTickets" component={DashboardTickets} />
        <Route path="/dashboardOrders" component={DashboardOrders} />
        <Route
          path="/dashboardHealthChecks"
          component={DashboardHealthChecks}
        />

        <Route path="/Account_Name" component={Account_Name} />
        <Route path="/Service_Address" component={Service_Address} />
        <Route path="/Phone_Number" component={Phone_Number} />
        <Route path="/Account_Number" component={Account_Number} />
        <Route path="/By_Contact_Email" component={By_Contact_Email} />

        <Route path="/Account_Details" component={Account_Details} />
        <Route path="/products" component={Products} />
        <Route path="/quotes" component={Quotes} />
        <Route path="/orders" component={Orders} />
        <Route path="/invoices" component={Invoices} />
        <Route path="/payments" component={Payments} />
        <Route path="/history" component={History} />
        <Route path="/orderitems" component={Orderitems} />
        {/* <Route path="/orderitemsCopy" component={OrderitemsCopy} /> */}
        <Route path="/myprofile" component={Myprofile} />
        <Route path="/newquote" component={Newquote} />
        <Route path="/editAccount" component={EditAccount} />
        <Route path="/new_quote" component={New_Quote} />
        <Route path="/New_Quote_2" component={New_Quote_2} />

        <Route path="/addnewNRC" component={AddNewNRC} />
        <Route path="/addnnewservice" component={AddnNewService} />
        <Route path="/Contacts" component={Contacts} />
        <Route path="/TicketsCoustomer" component={TicketsCoustomer} />

        {/* ////// administration///////7 */}
        <Route path="/Form_447_generator" component={Form_477_Generator} />
        <Route path="/Form_W9_generator" component={Form_W9_Generator} />
        <Route path="/ViewAgents" component={ViewAgents} />
        <Route path="/AddAgents" component={AddAgents} />
        <Route path="/ViewUsers" component={ViewUsers} />
        <Route path="/AddUsers" component={AddUsers} />
        <Route path="/EditUsers" component={EditUsers} />
        <Route path="/EditAgents" component={EditAgents} />
        <Route path="/AgentDetailsPage" component={AgentDetailsPage} />
        <Route
          path="/ServiceAddressesSearch"
          component={ServiceAddressesSearch}
        />
        <Route path="/ServiceAddressesNew" component={ServiceAddressesNew} />
        <Route path="/AddServicesToAddress" component={AddServicesToAddress} />
        <Route path="/AddProducts" component={AddProducts} />
        <Route path="/ViewProducts" component={ViewProducts} />
        <Route path="/ViewProductsKeys" component={ViewProductsKeys} />
        <Route path="/AddProductsKeys" component={AddProductsKeys} />
        <Route path="/AddTickets" component={AddTickets} />
        <Route path="/ViewTickets" component={ViewTickets} />
        <Route path="/AssetTracking" component={AssetTracking} />
        <Route path="/AddContacts" component={AddContacts} />
        <Route path="/ViewContacts" component={ViewContacts} />

        <Route path="/ViewAccounts" component={ViewAccounts} />
        <Route path="/ViewLeads" component={ViewLeads} />
        <Route path="/AddLeads" component={AddLeads} />
        <Route path="/EventTickets" component={EventTickets} />
        <Route path="/EditLeads" component={EditLeads} />
        <Route path="/CarrierDestinations" component={CarrierDestinations} />
        <Route path="/Carriers" component={Carriers} />
        <Route path="/CarrierDestinations" component={CarrierDestinations} />
        <Route path="/CarrierIPs" component={CarrierIPs} />
        <Route path="/CustomerIPs" component={CustomerIPs} />
        <Route path="/DIDDestinationGroups" component={DIDDestinationGroups} />
        <Route path="/DIDs" component={DIDs} />
        <Route path="/InternationalRates" component={InternationalRates} />
        <Route path="/Rates" component={Rates} />
        <Route path="/CDR" component={CDR} />
        <Route path="/General" component={General} />
        <Route path="/BlackListSettings" component={BlackListSettings} />
        <Route path="/ViewVoiceApps" component={ViewVoiceApp} />
        <Route path="/AddVoiceApp" component={AddVoiceApp} />
        <Route path="/Prueba" component={Prueba} />

        {/* <Route path="/editContacts" component={EditContacts} />     */}
        <Route path="/google-material" component={GoogleMaterialPage} />
        <Route path="/react-bootstrap" component={ReactBootstrapPage} />
        <Route path="/docs" component={DocsPage} />
        <Redirect to="/error/error-v1" />
      </Switch>
    </Suspense>
  );
}
