import axios from "axios";
import interceptor from "./petition_interceptor";

export default function path_user(key, valone, valtwo) {
  const json = require("../../../../config.json");
  const urlBase = json.urlBase;
  const token = JSON.parse(localStorage.getItem("persist:demo4-auth"));
  var url;
  var data;
  // var urlBase = "http://api.itpscorp.com/dev/crmui";
  // var urlBase = "https://api.itpscorp.com/crmui"
  // if (token.authToken) {
  switch (key) {
    case "profile":
      url = urlBase + "/profile";
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valone;
      break;

    case "updatecc":
      url = urlBase + "/accounts/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/accounts/"+valone+"/credit-card";
      data = valtwo;
      console.log(valtwo);
      break;
    case "asterikQueues":
      url = urlBase + "/itpvoice/queue-servers/" + valone;
      data = valtwo;
      break;
    case "editaccount":
      console.log(valtwo);
      url = urlBase + "/accounts/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/accounts/"+valone;
      data = {
        // pk: 211,
        name: valtwo.name,
        phone: valtwo.phone,
        website: valtwo.website,
        billing_address: valtwo.billing_address,
        billing_city: valtwo.billing_city,
        billing_state: valtwo.billing_state,
        billing_zipcode: valtwo.billing_zipcode,
        billing_country: valtwo.billing_country,
        billing_address_suite: valtwo.billing_address_suite,
        industry: valtwo.industry,
        // accountnumber: 45782022,
        status: valtwo.status,
        net_terms: valtwo.net_terms,
        // imgurl: null,
        // billing_api_id: 4a8c86f882294c4eb80c8ffeb229d908,
        account_type: valtwo.account_type,
        // account_type: "residential",
        agent_id: valtwo.agent_id,
        billing_cycle_month_date: valtwo.billing_cycle_month_date,
        ebilling: valtwo.ebilling,
        automatic_payments: valtwo.automatic_payments,
        tax_exempt: valtwo.tax_exempt,
      };
      break;
    case "editContacts":
      url = urlBase + "/contacts/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "updateService":
      url = urlBase + "/services/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;

    case "quoteitemsService":
      url = urlBase + "/quoteitems/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      console.log(url);
      data = valtwo;
      break;
    case "calendar":
      url = urlBase + "/calendar/" + valone;
      data = valtwo;
      break;

    case "changeQuoteService":
      url = urlBase + "/quoteitems/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      console.log(valtwo);
      data = valtwo;
      break;

    case "service-address":
      url = urlBase + "/service-address/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "service-address-edit":
      url = urlBase + "/services/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "products":
      console.log("dadsads");
      url = urlBase + "/products/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "tickets":
      url = urlBase + "/tickets/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "agents":
      url = urlBase + "/agents/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "users":
      url = urlBase + "/users/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "quotes":
      url = urlBase + "/quotes/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "orderitems":
      url = urlBase + "/orderitems/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "leads":
      url = urlBase + "/leads/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "carriers":
      url = urlBase + "/sbc/carriers/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "carrierIPS":
      url = urlBase + "/sbc/carrier-ips/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "dids":
      url = urlBase + "/sbc/dids/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "rates":
      url = urlBase + "/sbc/rates/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "internationalRates":
      url = urlBase + "/sbc/international-rates/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;

    case "customerIps":
      url = urlBase + "/sbc/customer-ips/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "carrierDestinations":
      url = urlBase + "/sbc/carrier-destinations/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "didDestinationGroups":
      url = urlBase + "/sbc/did-destination-groups/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "statusEvent":
      url = urlBase + "/ticket-events/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "ticket-blacklist":
      url = urlBase + "/ticket-blacklist/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "agenPayaouts":
      url = urlBase + "/agentpayouts/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "serviceAddons":
      url = urlBase + "/service-addons/" + valone;
      // url = "http://api.itpscorp.com/dev/crmui/profile";
      data = valtwo;
      break;
    case "ITPVoice":
      // console.log(value);
      url = urlBase + "/itpvoice/app-store/" + valone;
      data = valtwo;
      break;
    case "appPage":
      // console.log(value);
      url = urlBase + "/itpvoice/apps/" + valone.pk + "/pages/" + valone.pkPage;
      data = valtwo;
      break;
    case "changeAddressService":
      // console.log(value);
      url = urlBase + "/services/" + valone.pk;
      data = valtwo;
      break;
    case "assets":
      // console.log(value);
      url = urlBase + "/asset-tracking" + valone;
      data = valtwo;
      break;
    default:
      return "error";
      break;
  }
  var config = {
    headers: {
      Authorization: "Bearer " + token.authToken.replace(/['"]+/g, ""),
    },
  };

  return interceptor.patch(url, data);
  // } else {
  //   window.location.href = "./Dashboard";
  // }
}
