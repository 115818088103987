import React from "react";
import { Modal,Spinner,Button } from "react-bootstrap";
function seespinner(val){
  if(val){
    return "initial";
  }else{
    return "none";
  }
}
export default function LoadingModalMessage(props) {
    return (
      <>
        <Modal
        show={props.show}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
      >
        <Modal.Header >
          <Modal.Title id="contained-modal-title-vcenter">
           <p style={{display:seespinner(props.spinner)}}>Loading</p>
           <p style={{display:seespinner(!props.spinner)}}>{props.tittleMessage}</p> 
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ display:"flex", justifyContent:"center" }}>
          <Spinner animation="grow" style={{marginTop:"10vh",marginBottom:"10vh",height: "10vh", width: "10vh", display:seespinner(props.spinner)}}/>
        <h4 style={{display:seespinner(!props.spinner)}}>{props.message}</h4>
        
        </Modal.Body>
        <Modal.Footer>
          <Button style={{display:seespinner(!props.spinner)}} onClick={props.onHide} variant="danger">Close</Button>
        </Modal.Footer>
      </Modal>
      </>
    );
  }