import React from "react";
import Paper from "@material-ui/core/Paper";
import { Table, Button, Dropdown } from "react-bootstrap";
import petition_user from "../../petitions/petition_user";
import { Link } from "react-router-dom";
import DateRangePicker from "react-bootstrap-daterangepicker";
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from "moment"
// import PortletHeaderDropdown from "../../sub_menu_details";
import Await from "../../utils/await";
// import EditOrders from "./EditOrders/EditOrders";
import * as qs from "query-string";

export default class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.urlData = qs.parse(this.props.location.search);
    this.state = {
      data: {},
      loading: true,
      status: "all",
      timefilter: {
        timeStart: "",
        timeEnd: "",
      },
      sendFilter: "",

    };
  }
  componentDidMount() {

    let timeStart = new Date();
    timeStart.setMonth(timeStart.getMonth() - 3)

    let timeEnd = new Date();

    petition_user("Allorders", `?start_date=${timeStart.toISOString().split(".")[0]}&end_date=${timeEnd.toISOString().split(".")[0]}`)
      .then(({ data: { result } }) => {
        console.log(result);

        this.setState({
          data: result.reverse(),
          loading: false,
          timefilter: {
            timeStart: timeStart,
            timeEnd: timeEnd,
          },
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }


  onChangeDate(event, picker) {
    if (event.type === "apply") {
      this.setState({
        timefilter: {
          timeStart: picker.startDate._d,
          timeEnd: picker.endDate._d,
        }

      })
    }
  }


  onSubmitFilter() {

    let timeStart = new Date(this.state.timefilter.timeStart);
    let timeEnd = new Date(this.state.timefilter.timeEnd);



    this.setState({
      loading: true,
    })
    petition_user("Allorders", `?${this.state.status === "all" ? "" : `status=${this.state.status}&`}start_date=${timeStart.toISOString().split(".")[0]}&end_date=${timeEnd.toISOString().split(".")[0]}`)
      .then(({ data: { result } }) => {


        this.setState({
          data: result.reverse(),
          loading: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });

  }

  render() {

    if (this.state.loading === true) {
      return <Await />;
    } else {

      return (
        <>
          <Paper style={{ padding: "3vh 2vh" }}>
            <div style={{ display: "flex", justifyContent: "flex-end" }}>
              {/* <PortletHeaderDropdown
                pk={this.urlData.pk}
                accountName={this.urlData.accountName}
                style={{display: "none"}}
              /> */}
            </div>
            <div className="row">

              <div className="col">
                <label htmlFor="state">Status: </label>
                <select
                  id="state"
                  className="form-control"
                  value={this.state.status}
                  onChange={(e) => { this.setState({ status: e.target.value }) }}

                >
                  <option value="all">
                    All
                  </option>
                  <option value="new_order">
                    New Order
                  </option>
                  <option value="completed">
                    Completed
                  </option>
                  <option value="cancelled">
                    Cancelled
                  </option>

                </select>
              </div>
              <div className="col" style={{ position: "relative" }}>
                <label htmlFor="state">Created: </label>

                <DateRangePicker
                  initialSettings={{
                    ranges: {
                      Today: [moment(), moment()],
                      Yesterday: [
                        moment().subtract(1, "days"),
                        moment().subtract(1, "days"),
                      ],
                      "Last 7 Days": [moment().subtract(6, "days"), moment()],
                      "Last 30 Days": [
                        moment().subtract(29, "days"),
                        moment(),
                      ],
                      "This Month": [
                        moment().startOf("month"),
                        moment().endOf("month"),
                      ],
                      "Last Month": [
                        moment().subtract(1, "month").startOf("month"),
                        moment().subtract(1, "month").endOf("month"),
                      ],
                    },
                    startDate: this.state.timefilter.timeStart,
                    endDate: this.state.timefilter.timeEnd,
                  }}
                  onEvent={(e, picker) => { this.onChangeDate(e, picker) }}
                >
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "100%" }}
                  />
                </DateRangePicker>

              </div>
              <div className="col">

              </div>


              <div className="col" style={{ display: "flex", alignItems: "center", textAlign: "center", justifyContext: "flex-end" }}>
                <div className="text-right w-100">  <Button
                  onClick={() => { this.onSubmitFilter() }}
                >
                  Filter
                </Button> </div>


              </div>
            </div>
            <Table hover responsive="md" size="sm">
              <thead>
                <tr>
                  <th style={{ textAlign: "center" }}>Quote Id</th>
                  <th style={{ textAlign: "center" }}>Account Name</th>
                  <th style={{ textAlign: "center" }}>Quote Name</th>
                  <th style={{ textAlign: "center" }}>Status</th>
                  <th style={{ textAlign: "center" }}>created</th>
                  <th
                    style={{
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    Actions
                  </th>
                </tr>
              </thead>
              <tbody>
                {this.state.data.map((element, i) => (
                  <tr key={i}>
                    <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                      {element.pk}
                    </td>
                    <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                      {element.account.name}
                    </td>
                    <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                      {element.name}
                    </td>
                    <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                      {element.status}
                    </td>
                    <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                      {element.date_created}
                    </td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Button
                        size="sm"
                        onClick={() => {
                          this.props.history.push({
                            pathname: "./orderitems",
                            search:
                              "?accountName=" +
                              element.account.name.replace("#", "%23").replace("&", "%26") +
                              "&pk=" +
                              element.account.pk +
                              "&order_pk=" +
                              element.pk +
                              "&account_id=" +
                              element.account.pk +
                              "&quote_id=" +
                              element.quote_id,
                          });
                        }}
                      >
                        View
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </Paper>
        </>
      );
    }
  }
}
