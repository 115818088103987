/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Dropdown from "react-bootstrap/Dropdown";
// import Newquote from "../../../../app/pages/submenu/newquote";
// import Newaccount from "../../../../app/pages/submenu/newaccount";
import {Link} from "react-router-dom";

const QuickActionsDropdownToggle = React.forwardRef((props, ref) => {
  return (
    <a
      ref={ref}
      href="#"
      onClick={(e) => {
        e.preventDefault();
        props.onClick(e);
      }}
      id=""
      className="btn btn-danger kt-subheader__btn-options"
    >
      Quick Actions
    </a>
  );
});

export class QuickActions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modalshowquote: false,
      modalshowacocunt: false,
    };
  }
  render() {
    let modalclosequote = () => this.setState({ modalshowquote: false });
    let modalcloseaccount = () => {
      console.log("onhide");
      this.setState({ modalshowacocunt: false });
    }

    return (
      <>
        {/* <Newquote show={this.state.modalshowquote} onHide={modalclosequote} /> */}
        {/* <Newaccount
          show={this.state.modalshowacocunt}
          onHide={modalcloseaccount}
        /> */}
        <OverlayTrigger
          placement="top"
          overlay={<Tooltip id="quick-actions-tooltip">Quick actions</Tooltip>}
        >
          <Dropdown className="dropdown-inline" drop="down" alignRight>
            <Dropdown.Toggle
              as={QuickActionsDropdownToggle}
              id="dropdown-toggle-quick-actions-subheader"
            />

            <Dropdown.Menu className="dropdown-menu dropdown-menu-right">
              <Link
                className="dropdown-item"
                to="./Newquote"
              >
                <i className="la la-plus"></i> New Customer Quote
              </Link>
              {/* <a
                className="dropdown-item"
                onClick={() => {
                  this.setState({ modalshowacocunt: true });
                }}
              >
                <i className="la la-user"></i> New User
              </a>
              <a className="dropdown-item">
                <i className="la la-cloud-download"></i> New Download
              </a>
              <div className="dropdown-divider"></div>
              <a className="dropdown-item">
                <i className="la la-cog"></i> Settings
              </a> */}
            </Dropdown.Menu>
          </Dropdown>
        </OverlayTrigger>
      </>
    );
  }
}
