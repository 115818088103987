import React, { useEffect, useMemo, useRef, useState } from "react";
import { Chart } from "chart.js";
import { useSelector } from "react-redux";
import { metronic } from "../../_metronic";
import CurrencyFormat from "react-currency-format";
import Graphics from "./Graphics/Graphics";

export default function OrderStatisticsChart(props) {
  console.log(props);
  const ref = useRef();
  const [months, setMonths] = useState([]);
  const [monthsValues, setMonthsValues] = useState([]);
  const [monthsInvoice, setMonthsInvoice] = useState([]);
  const [monthsValuesInvoice, setMonthsValuesInvoice] = useState([]);
  const [max, setMax] = useState(0);
  const [billingPayments, setBillingPayments] = useState({});

  useEffect(() => {
    if (months !== props.months) {
      setMonths(props.months);
    }
    if (monthsValues !== props.monthsValues) {
      setMonthsValues(props.monthsValues);
    }
    if (monthsInvoice !== props.monthsInvoice) {
      setMonthsInvoice(props.monthsInvoice);
    }
    if (monthsValuesInvoice !== props.monthsValuesInvoice) {
      setMonthsValuesInvoice(props.monthsValuesInvoice);
    }
    if (max !== props.max) {
      setMax(props.max);
    }
    if (billingPayments !== props.billingPayments) {
      console.log(props.billingPayments.invoice_stats);
      setBillingPayments(props.billingPayments);
    }
  });
  const { brandColor, shape2Color, shape3Color } = useSelector((state) => ({
    brandColor: metronic.builder.selectors.getConfig(
      state,
      "colors.state.brand"
    ),
    shape2Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.2"
    ),
    shape3Color: metronic.builder.selectors.getConfig(
      state,
      "colors.base.shape.3"
    ),
  }));

  const data = {
    labels: months,
    datasets: [
      {
        fill: true,
        // borderWidth: 0,
        backgroundColor: Chart.helpers.color(brandColor).alpha(0.6).rgbString(),

        borderColor: Chart.helpers.color(brandColor).alpha(0).rgbString(),

        pointHoverRadius: 4,
        pointHoverBorderWidth: 12,
        pointBackgroundColor: Chart.helpers
          .color("#000000")
          .alpha(0)
          .rgbString(),
        pointBorderColor: Chart.helpers.color("#000000").alpha(0).rgbString(),
        pointHoverBackgroundColor: brandColor,
        pointHoverBorderColor: Chart.helpers
          .color("#000000")
          .alpha(0.1)
          .rgbString(),

        data: monthsValues,
      },
      {
        fill: true,
        // borderWidth: 0,
        backgroundColor: Chart.helpers.color(brandColor).alpha(0.2).rgbString(),
        borderColor: Chart.helpers.color(brandColor).alpha(0).rgbString(),

        pointHoverRadius: 4,
        pointHoverBorderWidth: 12,
        pointBackgroundColor: Chart.helpers
          .color("#000000")
          .alpha(0)
          .rgbString(),
        pointBorderColor: Chart.helpers.color("#000000").alpha(0).rgbString(),
        pointHoverBackgroundColor: brandColor,
        pointHoverBorderColor: Chart.helpers
          .color("#000000")
          .alpha(0.1)
          .rgbString(),

        data: monthsValues,
      },
    ],
  };

  // [brandColor];

  return (
    <div className="kt-widget12">
      <div className="kt-widget12__content">
        <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">
              Average Customer Payment Amount
            </span>
            <span className="kt-widget12__value">
              <CurrencyFormat
                fixedDecimalScale={true}
                fixedDecimalScale={true}
                decimalScale={2}
                value={
                  billingPayments.invoice_stats !== undefined
                    ? billingPayments.invoice_stats.average_payment_amount
                    : ""
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Average</span>
            <span className="kt-widget12__value">
              <CurrencyFormat
                fixedDecimalScale={true}
                fixedDecimalScale={true}
                decimalScale={2}
                value={
                  billingPayments.invoice_stats !== undefined
                    ? billingPayments.invoice_stats.average_month
                    : ""
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          </div>
        </div>
        <div className="kt-widget12__item">
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Maximum payment</span>
            <span className="kt-widget12__value">
              <CurrencyFormat
                fixedDecimalScale={true}
                fixedDecimalScale={true}
                decimalScale={2}
                value={
                  billingPayments.invoice_stats !== undefined
                    ? billingPayments.invoice_stats.maximum_payment_amount
                    : ""
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          </div>
          <div className="kt-widget12__info">
            <span className="kt-widget12__desc">Minimum payment</span>
            <span className="kt-widget12__value">
              <CurrencyFormat
                fixedDecimalScale={true}
                fixedDecimalScale={true}
                decimalScale={2}
                value={
                  billingPayments.invoice_stats !== undefined
                    ? billingPayments.invoice_stats.minimum_payment_amount
                    : ""
                }
                displayType={"text"}
                thousandSeparator={true}
                prefix={"$"}
              />
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}
