import React from "react";
import { Modal, Button, Spinner } from "react-bootstrap";
import petition_user from "../../../../petitions/petition_user"
import petition_patch from "../../../../petitions/petition_patch"
import AutoCompleteITP from "../AddTickets/AutoComplete/AutoComplete";
import AutoCompleteUsers from "./AutoComplete/AutoComplete";
import AutoCompleteAssignedUser from "./AutoCompleteAssignedUser/AutoComplete";
import AutoCompleteAssignedTech from "./AutoCompleteAssignedTech/AutoComplete";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";   

export default class Edit extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      spinner: false,
      states: ["new", "pending_customer", "pending_itp", "closed"],
      stateSelected: this.props.element.status,
      element: this.props.element,
      pkAccount: null,
      account_name: "",
      editAccount: false,
      reporter_users: [],
      assigned_user: null,
      assigned_tech: null,
      reporter_user: null,
      assigned_user_id: null,
      assigned_tech_id: null,
      reporter_user_id: null,
      editAssignedUser: null,
      editAssignedTech: null,
      editReporterUser: null,
    };
  }

  seespinner(val) {
    if (val) {
      return "initial";
    } else {
      return "none";
    }
  }
  valChange = (val) => {
    this.setState({ pkAccount: val.pk });
  };

  valChangeAssignedUser = (val) => {
    console.log(val.pk);
    this.setState({
      assigned_user_id: val.pk,
    });
  };

  valChangeAssignedTech = (val) => {
    console.log(val.pk);
    this.setState({
      assigned_tech_id: val.pk,
    });
  };

  valChangeReporterUser = (val) => {
    console.log(val.pk);
    this.setState({
      reporter_user_id: val.pk,
    });
  };

  componentDidMount() {
    console.log(this.props.element);
    this.setState({
      account_name: this.props.element.account.name,
      pkAccount: this.props.element.account.pk,
      assigned_user: this.props.element.assigned_user,
      assigned_user_id: this.props.element.assigned_user_id,
      reporter_user: this.props.element.reporter_user,
      reporter_user_id: this.props.element.reporter_user_id,
      assigned_tech: this.props.element.assigned_tech,
      assigned_tech_id: this.props.element.assigned_tech_id,
    });
    petition_user("users")
      .then(({ data: { result } }) => {
        this.setState({ reporter_users: result });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  send() {
    this.setState({ spinner: true });
    let data = {
      status: this.state.stateSelected,
    };

    if (this.state.pkAccount != null && this.state.pkAccount != "") {
      data["account_id"] = this.state.pkAccount;
    }
    if (
      this.state.assigned_user_id != null &&
      this.state.assigned_user_id != ""
    ) {
      data["assigned_user_id"] = this.state.assigned_user_id;
    }

    if (
      this.state.reporter_user_id != ""
    ) {
      data.reporter_user_id = parseInt(this.state.reporter_user_id);
    }
    if (
      this.state.assigned_tech_id != ""
    ) {
      data.assigned_tech_id = parseInt(this.state.assigned_tech_id);
    }
    console.log(data);
    petition_patch("tickets", this.props.element.pk, data)
      .then(({ data: { result } }) => {
        window.location.reload(true);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          spinner: false,
        });
      });
  }

  render() {
    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            Description
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Spinner
              animation="grow"
              style={{
                marginTop: "10vh",
                marginBottom: "10vh",
                height: "10vh",
                width: "10vh",
                display: this.seespinner(this.state.spinner),
              }}
            />
          </div>
          <div
            style={{
              width: "100%",
              display: this.seespinner(!this.state.spinner),
            }}
          >
            <div className="row">
              <div className="col">
                <label>Account Name</label>
                {this.state.editAccount === true ? (
                  <AutoCompleteITP val={this.valChange} />
                ) : (
                  <input
                    style={{ marginBottom: "0.5em", marginTop: "0.5em" }}
                    className="form-control"
                    value={this.props.element.account.name}
                    disabled
                  />
                )}
                <Button
                  variant={
                    this.state.editAccount === true ? "danger" : "primary"
                  }
                  onClick={() => {
                    this.setState({
                      editAccount: !this.state.editAccount,
                      pkAccount:
                        !this.state.editAccount === true
                          ? null
                          : this.props.element.account.pk,
                      account_name:
                        !this.state.pkAccount === true
                          ? null
                          : this.props.element.account.name,
                    });
                  }}
                >
                  {this.state.editAccount === true ? "Cancel" : "Change"}
                </Button>
              </div>
              <div className="col">
                <label>Reported User</label>
                {this.state.editReporterUser === true ? (
                  <AutoCompleteUsers val={this.valChangeReporterUser} />
                ) : (
                  <input
                    style={{ marginBottom: "0.5em", marginTop: "0.5em" }}
                    className="form-control"
                    value={
                      this.state.element.reporter_user.first_name +
                      " " +
                      this.state.element.reporter_user.last_name
                    }
                    disabled
                  />
                )}
                <Button
                  variant={
                    this.state.editReporterUser === true ? "danger" : "primary"
                  }
                  onClick={() => {
                    this.setState({
                      editReporterUser: !this.state.editReporterUser,
                    });
                  }}
                >
                  {this.state.editReporterUser === true ? "Cancel" : "Change"}
                </Button>
              </div>
            </div>

            <div className="row" style={{ marginTop: "2em" }}>
              <div className="col">
                <label>Assigned User</label>
                {this.state.editAssignedUser === true ? (
                  <AutoCompleteAssignedUser val={this.valChangeAssignedUser} />
                ) : (
                  <input
                    style={{ marginBottom: "0.5em", marginTop: "0.5em" }}
                    className="form-control"
                    value={
                      this.state.element.assigned_user === null
                        ? null
                        : this.state.element.assigned_user.first_name +
                          " " +
                          this.state.element.assigned_user.last_name
                    }
                    disabled
                  />
                )}
                <Button
                  variant={
                    this.state.editAssignedUser === true ? "danger" : "primary"
                  }
                  onClick={() => {
                    this.setState({
                      editAssignedUser: !this.state.editAssignedUser,
                    });
                  }}
                >
                  {this.state.editAssignedUser === true ? "Cancel" : "Change"}
                </Button>
              </div>

              <div className="col">
                <label>Assigned Tech</label>
                {this.state.editAssignedTech === true ? (
                  <AutoCompleteAssignedTech val={this.valChangeAssignedTech} />
                ) : (
                  <input
                    style={{ marginBottom: "0.5em", marginTop: "0.5em" }}
                    className="form-control"
                    value={
                      this.state.element.assigned_tech === null
                        ? null
                        : this.state.element.assigned_tech.first_name +
                          " " +
                          this.state.element.assigned_tech.last_name
                    }
                    disabled
                  />
                )}
                <Button
                  variant={
                    this.state.editAssignedTech === true ? "danger" : "primary"
                  }
                  onClick={() => {
                    this.setState({
                      editAssignedTech: !this.state.editAssignedTech,
                    });
                  }}
                >
                  {this.state.editAssignedTech === true ? "Cancel" : "Change"}
                </Button>
              </div>
            </div>

            <div className="row">
              <div className="col">
                <label htmlFor="state" style={{ marginTop: "2em" }}>
                  State:{" "}
                </label>
                <FormControl
                  variant="outlined"
                  margin="dense"
                  id="state"
                  fullWidth
                >
                  <Select
                    native
                    value={this.state.stateSelected}
                    onChange={(e) => {
                      this.setState({ stateSelected: e.target.value });
                    }}
                  >
                    {this.state.states.map((element, i) => (
                      <option key={i}>{element}</option>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="col"></div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide} variant="danger">
            Close
          </Button>
          <Button
            onClick={() => this.send()}
            variant="primary"
            disabled={
              (this.state.pkAccount === null &&
                this.state.editAccount === true) ||
              (this.state.editAssignedUser === true &&
                this.state.assigned_user_id === null)
            }
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
