import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";

export default function changePassword(token, val) {
  const json = require("../../../config.json");
  const urlBase = json.urlBase;
  var url;
  var data;

  if (token) {
    url = urlBase + "/profile";
    data = { password: val};
    var config = {
      headers: {
        Authorization: "Bearer " + token.replace(/['"]+/g, ""),
      },
    };

    return axios.patch(url,data,config);
  } else {
    return <Redirect exact from="/" to="/logout" />;
  }

}
