const convert = (utcDate) => {
  const date = new Date(utcDate);
  let newDate = Date.UTC(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    date.getHours(),
    date.getMinutes(),
    date.getSeconds()
  );
  const dataLocal = new Date(newDate);
  return `${dataLocal.getFullYear()}-${
    dataLocal.getMonth() === 0 ? "01" : `${dataLocal.getMonth() + 1}`
  }-${dataLocal.getDate()} ${
    dataLocal.getHours() < 10
      ? `0${dataLocal.getHours()}`
      : `${dataLocal.getHours()}`
  }:${
    dataLocal.getMinutes() < 10
      ? `0${dataLocal.getMinutes()}`
      : `${dataLocal.getMinutes()}`
  }:${
    dataLocal.getSeconds() < 10
      ? `0${dataLocal.getSeconds()}`
      : `${dataLocal.getSeconds()}`
  }`;
};

export default convert;
