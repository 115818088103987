import React from "react";
import axios from "axios";
import { Redirect } from "react-router-dom";
import interceptor from "./petition_interceptor";

export default function petition_user(key, val, parameter) {
  const json = require("../../../../config.json");
  const urlBase = json.urlBase;
  const token = JSON.parse(localStorage.getItem("persist:demo4-auth"));
  // console.log(token);
  var petition_get;
  // var urlBase = "http://api.itpscorp.com/dev/crmui";
  // var urlBase = "https://api.itpscorp.com/crmui";

  // console.log(json.urlBase);

  // if (token.authToken) {
  //   // console.log(token);
  if (key === "services") {
    petition_get = urlBase + "/services?account_id=" + val;
  } else if (key === "getCalendar") {
    petition_get = urlBase + "/calendar";
  } else if (key === "asterikQueues") {
    petition_get = urlBase + "/itpvoice/queue-servers";
  } else if (key === "invoices") {
    petition_get = urlBase + "/invoices?account_id=" + val;
  } else if (key === "servicesAddonNull") {
    petition_get = urlBase + "/services/" + val;
  } else if (key === "servicesAll") {
    petition_get = urlBase + "/services" + val;
  } else if (key === "assets") {
    petition_get = urlBase + "/asset-tracking" + val;
  } else if (key === "serviceAddons") {
    petition_get = urlBase + "/service-addons/" + val;
  } else if (key === "orders") {
    petition_get = urlBase + "/orders?account_id=" + val;
  } else if (key === "Allorders") {
    petition_get = urlBase + "/orders" + val;
  } else if (key === "quotes") {
    petition_get = urlBase + "/quotes?account_id=" + val;
  } else if (key === "getAccountsInactive") {
    petition_get = urlBase + "/accounts?past_due=true&status=active" + val;
  } else if (key === "orderitems") {
    petition_get = urlBase + "/orderitems?order_id=" + val;
  } else if (key === "allorderitems") {
    petition_get = urlBase + "/orderitems" + val;
  } else if (key === "products") {
    if (val === "") {
      petition_get = urlBase + "/products";
    } else {
      petition_get = urlBase + "/products/" + val;
    }
  } else if (key === "productsSearch") {
    petition_get = urlBase + "/products" + val;
  } else if (key === "provision") {
    petition_get = urlBase + "/orderitems/" + val + "/provision";
  } else if (key === "complete") {
    petition_get = urlBase + "/orderitems/" + val + "/complete";
  } else if (key === "profile") {
    petition_get = urlBase + "/profile";
  } else if (key === "agents") {
    petition_get = urlBase + "/agents/" + val;
  } else if (key === "agentsFilter") {
    petition_get = urlBase + "/agents" + val;
  } else if (key === "agentsall") {
    petition_get = urlBase + "/agents" + val;
  } else if (key === "payments") {
    petition_get = urlBase + "/payments" + "?account_id=" + val;
  } else if (key === "contacts") {
    petition_get = urlBase + "/contacts?account_id=" + val;
  } else if (key === "reset-password") {
    petition_get = urlBase + "/contacts/" + val + "/reset-password";
  } else if (key === "reset-password-agents") {
    petition_get = urlBase + "/agents/" + val + "/reset-password";
  } else if (key === "PDF-Invoices") {
    petition_get = urlBase + "/invoices/" + val + "/pdf";
  } else if (key === "Form477Generator") {
    petition_get = urlBase + "/service-address/generate-fcc-477";
  } else if (key === "service-address") {
    petition_get = urlBase + "/service-address?search=" + val;
  } else if (key === "service-address-only") {
    petition_get = urlBase + "/service-address";
  } else if (key === "productServices") {
    petition_get = urlBase + "/products?service_address_id=" + val;
  } else if (key === "service-address-quote") {
    petition_get = urlBase + "/service-address/" + val;
  } else if (key === "allProducts") {
    petition_get = urlBase + "/products" + val;
  } else if (key === "history") {
    petition_get =
      urlBase + "/history?account_id=" + val + "&" + "order_by=created";
  } else if (key === "quoteitems?quote_id") {
    petition_get = urlBase + "/quoteitems?quote_id=" + val;
    console.log(petition_get);
  } else if (key === "quoteId") {
    petition_get = urlBase + "/quotes/" + val;
  } else if (key === "billingPayments") {
    petition_get =
      urlBase +
      "/dashboard/billing/payments?start_year=" +
      val.year1 +
      "&end_year=" +
      val.year2;
  } else if (key === "lateAccounts") {
    petition_get = urlBase + "/dashboard/billing/late-accounts";
  } else if (key === "product-keys") {
    petition_get = urlBase + "/product-keys" + val;
  } else if (key === "service-address-detail") {
    petition_get = urlBase + "/service-address/" + val;
  } else if (key === "refresh") {
    petition_get = urlBase + "/auth/refresh";
  } else if (key === "tickets") {
    petition_get = urlBase + "/tickets" + val;
  } else if (key === "ticket-events") {
    petition_get = urlBase + "/ticket-events?ticket_id=" + val;
  } else if (key === "users") {
    petition_get = urlBase + "/users" + val;
  } else if (key === "accountsall") {
    petition_get = urlBase + "/accounts" + val;
  } else if (key === "accountsall-search") {
    petition_get = urlBase + "/accounts" + val;
  } else if (key === "users-search") {
    petition_get = urlBase + "/users?unlimit=true&first_name=" + val;
  } else if (key === "user-pk") {
    petition_get = urlBase + "/users/" + val;
  } else if (key === "quotePDF") {
    petition_get = urlBase + "/quotes/" + val + "/pdf";
  } else if (key === "reset-password-user") {
    petition_get = urlBase + "/users/" + val + "/reset-password";
  } else if (key === "contact_email") {
    petition_get = urlBase + "/search?contact_email=" + val;
  } else if (key === "leads") {
    petition_get = urlBase + "/leads" + val;
  } else if (key === "leadsources") {
    petition_get = urlBase + "/leadsources";
  } else if (key === "leads_pk") {
    petition_get = urlBase + "/leads/" + val;
  } else if (key === "coustomerLookUp_account_name") {
    petition_get = urlBase + "/global-search?account_name=" + val;
  } else if (key === "coustomerLookUp_account_number") {
    petition_get = urlBase + "/global-search?account_number=" + val;
  } else if (key === "coustomerLookUp_contact_email") {
    petition_get = urlBase + "/global-search?contact_email=" + val;
  } else if (key === "coustomerLookUp_phone_number") {
    petition_get = urlBase + "/global-search?phone_number=" + val;
  } else if (key === "coustomerLookUp_phone_number_pbx") {
    petition_get = urlBase + "/global-search?pbx_number=" + val;
  } else if (key === "coustomerLookUp_service_address") {
    petition_get = urlBase + "/global-search?service_address=" + val;
  } else if (key === "AgentCommissions") {
    petition_get = urlBase + "/agentcommissions?agent_id=" + val;
  } else if (key === "payouts") {
    petition_get = urlBase + "/agentpayouts?agent_id=" + val + (parameter || "");
  } else if (key === "reportAgents") {
    petition_get = urlBase + "/agents/" + val;
  } else if (key === "carriers") {
    petition_get = urlBase + "/sbc/carriers";
  } else if (key === "carriersSearch") {
    petition_get = urlBase + "/sbc/carriers" + val;
  } else if (key === "carrierIPS") {
    petition_get = urlBase + "/sbc/carrier-ips";
  } else if (key === "carrierIPSSearch") {
    petition_get = urlBase + "/sbc/carrier-ips" + val;
  } else if (key === "dids") {
    petition_get = urlBase + "/sbc/dids" + val;
  } else if (key === "didsSearch") {
    petition_get = urlBase + "/sbc/dids" + val;
  } else if (key === "rates") {
    petition_get = urlBase + "/sbc/rates" + val;
  } else if (key === "internationalRates") {
    petition_get = urlBase + "/sbc/international-rates";
  } else if (key === "internationalRatesSearch") {
    petition_get = urlBase + "/sbc/international-rates" + val;
  } else if (key === "customerIps") {
    petition_get = urlBase + "/sbc/customer-ips" + val;
  } else if (key === "customerIpsSearch") {
    petition_get = urlBase + "/sbc/customer-ips" + val;
  } else if (key === "carrierDestinations") {
    petition_get = urlBase + "/sbc/carrier-destinations" + val;
  } else if (key === "didDestinationGroups") {
    petition_get = urlBase + "/sbc/did-destination-groups";
  } else if (key === "didDestinationGroupsSearch") {
    petition_get = urlBase + "/sbc/did-destination-groups" + val;
  } else if (key === "carrierDestinationsSearch") {
    petition_get = urlBase + "/sbc/carrier-destinations" + val;
  } else if (key === "mytickets") {
    petition_get = urlBase + "/dashboard/tickets/mytickets" + val;
  } else if (key === "ticket-blacklist") {
    petition_get = urlBase + "/ticket-blacklist" + val;
  } else if (key === "CSR") {
    petition_get = urlBase + "/accounts/" + val + "/csr";
  } else if (key === "cdr") {
    petition_get = urlBase + "/sbc/cdr" + val;
  } else if (key === "allContacts") {
    petition_get = urlBase + "/contacts" + val;
  } else if (key === "impersonate") {
    petition_get = urlBase + "/contacts/" + val + "/impersonate";
  } else if (key === "impersonateAgents") {
    petition_get = urlBase + "/agents/" + val + "/impersonate";
  } else if (key === "contactSVC") {
    petition_get = urlBase + "/contacts" + val;
  } else if (key === "ITPVoice") {
    petition_get = urlBase + "/itpvoice/app-store" + val;
  } else if (key === "ITPVoiceAppPagesPagination") {
    petition_get =
      urlBase + "/itpvoice/apps/" + val.pk + "/pages?page_name=" + val.page;
  } else if (key === "ITPVoiceAppPages") {
    petition_get = urlBase + "/itpvoice/apps/" + val.pk + "/pages";
  } else if (key === "getInvoices") {
    petition_get =
      urlBase +
      "/invoice/dashboard?start_date=" +
      val.start_date +
      "&end_date=" +
      val.end_date;
  } else if (key === "getAddress") {
    petition_get = urlBase + "/accounts/" + val.id + "/service-addresses";
  } else if (key === "healthChecks") {
    petition_get = urlBase + "/health-check";
  } else {
    petition_get = urlBase + "/" + key + "/" + val;
  }

  // var config = {
  //   headers: {
  //     Authorization: "Bearer " + token.authToken.replace(/['"]+/g, ""),
  //   },
  // };

  // return interceptor.get(petition_get, config);
  // console.log(petition_get);

  return interceptor.get(petition_get);
  // } else {
  //   return <Redirect exact from="/" to="/logout" />;
  // }
}
