import axios from "axios";
import jwt_decode from "jwt-decode";
import * as utils from "../../../../_metronic/utils/utils";
import * as routerHelpers from "../../../router/RouterHelpers";
import * as auth from "../../../store/ducks/auth.duck";

const interceptor = axios.create();

interceptor.interceptors.request.use(
  (config) => {
    let data = {};
    const json = require("../../../../config.json");
    const urlBase = json.urlBase;
    const dateTime = Date.now();
    const timestamp = Math.floor(dateTime / 1000);
    let token = JSON.parse(
      localStorage.getItem("persist:demo4-auth")
    ).authToken.replace(/['"]+/g, "");
    let jwt = jwt_decode(token);
    config.headers.Authorization = token;
    if (
      timestamp > (jwt.exp - jwt.iat) * 0.8 + jwt.iat &&
      timestamp <= jwt.exp
    ) {
      // alert("mas");
      axios
        .get(urlBase + "/auth/refresh")
        .then(({ data: { result } }) => {
          // console.log(result);
          data = {
            authToken: result.access_token,
            user: result.first_name,
            _persist: `{"version":-1,"rehydrated":true}`,
          };

          window.localStorage.setItem(
            "persist:demo4-auth",
            JSON.stringify(data)
          );
        })
        .catch((error) => {
          // console.log(error);
          window.location.href = "/logout";
        });
    } else if (timestamp > jwt.exp) {
      window.location.href = "/logout";
    } else {
      // alert("menos");
    }

    return config;
  },
  (error) => Promise.reject(error)
);

// Add a response interceptor
interceptor.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    // alert("3");

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    return Promise.reject(error);
  }
);

export default interceptor;
