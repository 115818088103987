import React, { useState, useEffect } from "react";
import { Modal, Button, Table } from "react-bootstrap";

const ModalUtility = ({ modal, setModal, info, selector }) => {
  const [infoDetails, setIinfoDetails] = useState([]);

  useEffect(() => {
    if (info.length > 0) {
      info.map((element) => {
        element.numbers = element.numbers.join();
      });
      setIinfoDetails(info);
    }
  }, [info]);
  return (
    <Modal
      show={modal}
      aria-labelledby="contained-modal-title-vcenter"
      centered
      size="lg"
    >
      <Modal.Header>
        <Modal.Title>Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {infoDetails.length > 0 ? (
          <Table hover responsive="md" size="sm">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Account Id</th>
                <th style={{ textAlign: "center" }}>Numbers</th>
              </tr>
            </thead>
            <tbody>
              {infoDetails.map((element, i) => (
                <tr key={i}>
                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {element.account_id}
                  </td>
                  <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                    {element.numbers}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        ) : (
          <h6 className="d-flex justify-content-center align-items-center">
            currently there are no duplicate numbers
          </h6>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="primary" onClick={() => setModal(false)}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ModalUtility;
