import React from "react";
import { Modal, Button } from "react-bootstrap";

export default class ChangeDateModal extends React.Component {
  constructor(props) {
    super(props);
    this.actualDate = new Date().getFullYear();
    this.state = {
      date: this.actualDate,
      oldestDate: this.actualDate,
      arrayDate: [],
      arrayOldDate: [],
      error: false
    };
    console.log(this.state.date);
  }

  componentDidMount() {
    this.recentDate();
    this.oldestDate(this.state.date);
  }

  recentDate() {
    let arrayDate = [];
    for (let index = this.actualDate - 10; index <= this.actualDate; index++) {
      arrayDate.push(index);
    }

    this.setState({
      arrayDate: arrayDate.sort(function (a, b) {
        return b - a;
      }),
    });
  }

  oldestDate(val) {
    let arrayOldDate = [];
    for (let index = val - 10; index <= val; index++) {
      arrayOldDate.push(index);
    }
    this.setState({
      arrayOldDate: arrayOldDate.sort(function (a, b) {
        return b - a;
      }),
    });
  }

  render() {
    return (
      <Modal
        {...this.props}
        // show={true}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Modal heading
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col" style={{ width: "50%" }}>
              <label>From date </label>
              <select
                className="form-control"
                id="account_type"
                onChange={(e) => {
                  this.setState({ oldestDate: e.target.value });
                  this.props.setyear2(e.target.value)
                }}
              >
                {this.state.arrayOldDate.map((element) => (
                  <option value={element}>{element}</option>
                ))}
              </select>
            </div>
            <div className="col" style={{ width: "50%" }}>
              <label> To date </label>
              <select
                className="form-control"
                id="account_type"
                onChange={(e) => {
                  this.setState({ date: e.target.value });
                  /* this.oldestDate(e.target.value) */
                  this.props.setyear1(e.target.value)

                }}
              >
                {this.state.arrayDate.map((element, i) => (
                  <option key={i} value={element}>{element}</option>
                ))}
              </select>
            </div>


          </div>
          {this.state.error && <p style={{ color: "red" }}>Please,Your from date must be less than your to date </p>}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => this.props.onHide(false)} variant="danger">Close</Button>
          <Button onClick={() => { this.setState({ error: false }); if (parseInt(this.state.date) < parseInt(this.state.oldestDate)) return this.setState({ error: true }); this.props.requestapi(); this.props.onHide(false) }}>Change</Button>
        </Modal.Footer>
      </Modal>
    );
  }
}
