import React, { Fragment, useState, useEffect } from "react";
import { Table, Button, Badge } from "react-bootstrap";
import petition_user from "../../petitions/petition_user";
import Await from "../../utils/await";
import ModalDetails from "./ModalUitility/ModalUitility";

const DashboardHealthChecks = () => {
  const [healthCheckOne, setHealthCheckUno] = useState(null);
  const [healthCheckTwo, setHealthCheckTwo] = useState(null);
  const [healthCheckThree, setHealthCheckThree] = useState(null);
  const [healthCheckFour, setHealthCheckFour] = useState(null);
  const [loading, setLoading] = useState(true);
  const [infoHealthCheck, setInfoHealthCheck] = useState(null);
  const [modalDetails, setModalDetails] = useState(false);
  const [selectorHealthCheck, setSelectorHealthCheck] = useState("");

  const petition = () => {
    setLoading(true);
    petition_user("healthChecks")
      .then(({ data: result }) => {
        Object.keys(result.result).map((element) => {
          if (element === "healthcheck_1") {
            setHealthCheckUno(result.result[element]);
          } else if (element === "healthcheck_2") {
            setHealthCheckTwo(result.result[element]);
          } else if (element === "healthcheck_3") {
            setHealthCheckThree(result.result[element]);
          } else if (element === "healthcheck_4") {
            setHealthCheckFour(result.result[element]);
          }
        });
        setLoading(false);
      })
      .catch((error) => console.log(error));
  };

  const activeModalDetails = (info, healthcheck) => {
    setInfoHealthCheck(info);
    setSelectorHealthCheck(healthcheck);
    setModalDetails(true);
  };

  useEffect(() => {
    petition();
  }, []);

  return (
    <Fragment>
      {loading ? (
        <Await />
      ) : (
        <>
          {modalDetails && (
            <ModalDetails
              modal={modalDetails}
              setModal={setModalDetails}
              info={infoHealthCheck}
              selector={selectorHealthCheck}
            />
          )}

          <Table hover responsive="md" size="sm">
            <thead>
              <tr>
                <th style={{ textAlign: "center" }}>Name</th>
                <th style={{ textAlign: "center" }}>Description</th>
                <th style={{ textAlign: "center" }}>Status</th>
                <th
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                  HealthCheck 1
                </td>
                {healthCheckOne ? (
                  <>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {healthCheckOne.description}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Badge
                        style={{
                          height: "1.5rem",
                          marginTop: "6px",
                          paddingBottom: "0",
                          paddingTop: "3px",
                        }}
                        variant={`${
                          healthCheckOne.status === "Failed"
                            ? "danger"
                            : "success"
                        }`}
                      >
                        {healthCheckOne.status}
                      </Badge>
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Button className="invisible" size="sm">
                        Details
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Button className="invisible" size="sm">
                        Details
                      </Button>
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                  HealthCheck 2
                </td>
                {healthCheckTwo ? (
                  <>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {healthCheckTwo.description}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Badge
                        style={{
                          height: "1.5rem",
                          marginTop: "6px",
                          paddingBottom: "0",
                          paddingTop: "3px",
                        }}
                        variant={`${
                          healthCheckTwo.status === "Failed"
                            ? "danger"
                            : "success"
                        }`}
                      >
                        {healthCheckTwo.status}
                      </Badge>
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Button className="invisible" size="sm">
                        Details
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Button className="invisible" size="sm">
                        Details
                      </Button>
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                  HealthCheck 3
                </td>
                {healthCheckThree ? (
                  <>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {healthCheckThree.description}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Badge
                        style={{
                          height: "1.5rem",
                          marginTop: "6px",
                          paddingBottom: "0",
                          paddingTop: "3px",
                        }}
                        variant={`${
                          healthCheckThree.status === "Failed"
                            ? "danger"
                            : "success"
                        }`}
                      >
                        {healthCheckThree.status}
                      </Badge>
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Button
                        size="sm"
                        onClick={() =>
                          activeModalDetails(
                            healthCheckThree.duplicate_numbers,
                            "healthCheckThree"
                          )
                        }
                      >
                        Details
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                  </>
                )}
              </tr>
              <tr>
                <td style={{ verticalAlign: "middle", textAlign: "center" }}>
                  HealthCheck 4
                </td>
                {healthCheckFour ? (
                  <>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      {healthCheckFour.description}
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Badge
                        style={{
                          height: "1.5rem",
                          marginTop: "6px",
                          paddingBottom: "0",
                          paddingTop: "3px",
                        }}
                        variant={`${
                          healthCheckFour.status === "Failed"
                            ? "danger"
                            : "success"
                        }`}
                      >
                        {healthCheckFour.status}
                      </Badge>
                    </td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    >
                      <Button
                        size="sm"
                        onClick={() =>
                          activeModalDetails(
                            healthCheckFour.duplicate_numbers,
                            "healthCheckFour"
                          )
                        }
                      >
                        Details
                      </Button>
                    </td>
                  </>
                ) : (
                  <>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                    <td
                      style={{ verticalAlign: "middle", textAlign: "center" }}
                    ></td>
                  </>
                )}
              </tr>
            </tbody>
          </Table>
        </>
      )}
    </Fragment>
  );
};

export default DashboardHealthChecks;
